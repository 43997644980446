import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchAllSlideshowsFromServer,
  createSlideshowOnServer,
  updateSlideshowOnServer,
  deleteSlideshowOnServer
} from '../../../api/slideshows/SlideshowsAPI';
import { map } from 'lodash';

const slideshowsSlice = createSlice({
  name: 'slideshows',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    populateSlideshows(state, action) {
      // Don't overwrite slideshows not owned by the currentUser
      // (i.e. student slideshows which the currentUser has permission to view)
      const slideshowsNotOwnedByCurrentUser = state.all.filter(slideshow => slideshow.project.user.id !== action.payload.currentUserId);
      // Store dateCreated values as milliseconds.
      let slideshowsWithNumericalDates = map(action.payload.slideshows, slideshow => {
        if (slideshow.dateCreated) {
          slideshow.dateCreated = (new Date(slideshow.dateCreated)).valueOf();
        }
        return slideshow;
      });
      state.all = slideshowsWithNumericalDates.concat(slideshowsNotOwnedByCurrentUser);
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchSlideshowsFailure(state) {
      state.isFetching = false;
      state.fetchError = true;
    },
    createSlideshow(state, action) {
      state.createError = false;
      if (action.payload.dateCreated) {
        action.payload.dateCreated = (new Date(action.payload.dateCreated)).valueOf();
      }
      state.all.push(action.payload);
    },
    createSlideshowFailure(state) {
      state.createError = true;
    },
    updateSlideshow(state, action) {
      state.updateError = false;
      let slideshow = state.all.find(slideshow => slideshow.id === action.payload.id)
      if (slideshow) {
        for (const [key, value] of Object.entries(action.payload)) {
          if (key === 'dateCreated') {
            slideshow[key] = (new Date(value)).valueOf();
          } else {
            slideshow[key] = value;
          }
        }
      }
    },
    updateSlideshowFailure(state) {
      state.updateError = true;
    },
    deleteSlideshow(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(slideshow => slideshow.id !== action.payload.id)
    },
    deleteSlideshowFailure(state) {
      state.deleteError = true;
    },
  }
});

export const fetchSlideshows = apiActionFactory(
  slideshowsSlice.actions.populateSlideshows,
  fetchAllSlideshowsFromServer,
  slideshowsSlice.actions.fetchSlideshowsFailure,
  true
);

export const createSlideshow = apiActionFactory(
  slideshowsSlice.actions.createSlideshow,
  createSlideshowOnServer,
  slideshowsSlice.actions.createSlideshowFailure,
  true
);

export const updateSlideshow = apiActionFactory(
  slideshowsSlice.actions.updateSlideshow,
  updateSlideshowOnServer,
  slideshowsSlice.actions.updateSlideshowFailure,
  true
);

export const deleteSlideshow = apiActionFactory(
  slideshowsSlice.actions.deleteSlideshow,
  deleteSlideshowOnServer,
  slideshowsSlice.actions.deleteSlideshowFailure
);

export const { createSlideshow: createSlideshowInRedux } = slideshowsSlice.actions;

export default slideshowsSlice.reducer;
