import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchStudentsFromServer = async function (payload) {
    try {
        let response = await axios.get('/api/courses/' + payload.id + '/students');
        return response.data?.students || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching students'));
    }
};
