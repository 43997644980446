import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// PublicRoutes redirect to a specific private page if the user is logged in (e.g. '/' can redirect to '/user')
function PublicRoute({ component: Component, isAuthenticated, redirectTo, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated !== true) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: redirectTo,
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string.isRequired
}

export default PublicRoute;
