import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchProjectsFromServer = async function (payload) {
    if (!payload.hasOwnProperty('currentUserId')) {
        throw new Error('Missing Parameter: fetchProjectFromServer requires that the payload contain <currentUserId>');
    }

    try {
        let response = await axios.get('/api/projects');
        return { projects: response.data || [], currentUserId: payload.currentUserId };
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching projects'));
    }
};

export const fetchProjectFromServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: fetchProjectFromServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.get('/api/projects/' + payload.id);
        return response.data;
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching projects'));
    }
};

export const createProjectOnServer = async function (payload) {
    if (!payload.hasOwnProperty('title')) {
        throw new Error('Missing Parameter: createProjectOnServer requires that the payload contain <title>');
    }

    try {
        let response = await axios.post('/api/projects', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No project object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/projects failed'));
    }
};

export const deleteProjectOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteProjectOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/projects/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/projects/' + payload.id + ' failed'));
    }
};

// Updates the project with all of the fields included in payload
// Valid fields are {title, timeLowerBound, timeUpperBound, audienceExpectations, message, presentationFormat, course, assignment}
export const updateProjectOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: updateProjectOnServer requires that the payload contain the project <id>');
    }

    try {
        let response = await axios.patch('/api/projects/' + payload.id, payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Invalid server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PUT /api/projects/' + payload.id + ' failed'));
    }
}

export const setTimeLowerBoundOnServer = async function (payload) {
    if (!payload.hasOwnProperty('timeLowerBound')) throw new Error('Missing Parameter: setTimeLowerBoundOnServer requires that the payload contain <timeLowerBound>');
    return updateProjectOnServer(payload);
};

export const setTimeUpperBoundOnServer = async function (payload) {
    if (!payload.hasOwnProperty('timeUpperBound')) throw new Error('Missing Parameter: setTimeUpperBoundOnServer requires that the payload contain <timeUpperBound>');
    return updateProjectOnServer(payload);
};

export const setMessageOnServer = async function (payload) {
    if (!payload.hasOwnProperty('message')) throw new Error('Missing Parameter: setMessageOnServer requires that the payload contain <message>');
    return updateProjectOnServer(payload);
};

export const setAudienceExpectationsOnServer = async function (payload) {
    if (!payload.hasOwnProperty('audienceExpectations')) throw new Error('Missing Parameter: setAudienceExpectationsOnServer requires that the payload contain <audienceExpectations>');
    return updateProjectOnServer(payload);
};

export const setFormatOnServer = async function (payload) {
    if (!payload.hasOwnProperty('presentationFormat')) throw new Error('Missing Parameter: setFormatOnServer requires that the payload contain <presentationFormat>');
    return updateProjectOnServer(payload);
};
