import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCalendarAlt, faPencilAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
// import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/features/user/userSelector';
import { getCourses } from '../../redux/features/courses/coursesSelector';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { trackUserNavbarEvent } from '../../assets/utils/GoogleAnalytics';
import './UserNavbar.scss';


const UserNavbar = () => {
  const location = useLocation();
  const currentUser = useSelector(getUser);
  const courses = useSelector(getCourses);

  return <div className={'user-nav d-none ' + (location.pathname.includes('/user/course/') ? 'd-md-block' : 'd-sm-block')}>
    <nav aria-label='User Navigation Bar'>
      {(!currentUser?.instructor && currentUser?.student?.isIndependentUser) &&
        <Link
          to='/user/projects'
          onClick={() => trackUserNavbarEvent('Clicked Projects', currentUser?.instructor ? 'instructor' : 'student')}
          className={'clear-link-styling user-nav-btn user-projects-nav-btn no-select' + (location.pathname.includes('/user/project') ? ' active' : '')}
        >
          <FontAwesomeIcon icon={faPencilAlt} className='icon' />
          <span className='text'>Projects</span>
        </Link>
      }
      <Link
        to='/user/courses/active'
        onClick={() => trackUserNavbarEvent('Clicked Courses', currentUser?.instructor ? 'instructor' : 'student')}
        className={'clear-link-styling user-nav-btn user-courses-nav-btn no-select' + (location.pathname.includes('/user/course') ? ' active' : '')}
      >
        <FontAwesomeIcon icon={faBook} className='icon' />
        <span className='text'>Courses</span>
      </Link>
      {!currentUser?.instructor && courses?.length > 0 &&
        <Link
          to='/user/assignments'
          onClick={() => trackUserNavbarEvent('Clicked Assignments', currentUser?.instructor ? 'instructor' : 'student')}
          className={'clear-link-styling user-nav-btn user-assignments-nav-btn no-select' + (location.pathname.includes('/user/assignments') ? ' active' : '')}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className='icon' />
          <span className='text'>Assignments</span>
        </Link>
      }
      {(currentUser?.instructor || !currentUser?.student?.isIndependentUser) &&
        <Link
          to='/user/projects'
          onClick={() => trackUserNavbarEvent('Clicked Projects', currentUser?.instructor ? 'instructor' : 'student')}
          className={'clear-link-styling user-nav-btn user-projects-nav-btn no-select' + (location.pathname.includes('/user/project') ? ' active' : '')}
        >
          <FontAwesomeIcon icon={faPencilAlt} className='icon' />
          <span className='text'>Projects</span>
        </Link>
      }
      {courses?.length > 0 &&
        <Link
          to='/user/messages'
          onClick={() => trackUserNavbarEvent('Clicked Messages', currentUser?.instructor ? 'instructor' : 'student')}
          className={'clear-link-styling user-nav-btn user-messages-nav-btn no-select' + (location.pathname.includes('/user/message') ? ' active' : '')}
        >
          <FontAwesomeIcon icon={faEnvelopeOpenText} className='icon' />
          <span className='text'>Messages</span>
          {currentUser && currentUser.numberOfUnreadMessages > 0 &&
            <div className='unread-messages-indicator'>
              <div>{currentUser.numberOfUnreadMessages}</div>
            </div>
          }
        </Link>
      }
      {/* <Link
        to='/user/help'
        onClick={() => trackUserNavbarEvent('Clicked Help', currentUser?.instructor ? 'instructor' : 'student')}
        className={'clear-link-styling user-nav-btn user-help-nav-btn no-select' + (location.pathname.includes('/user/help') ? ' active' : '')}
      >
        <FontAwesomeIcon icon={faQuestionCircle} className='icon' />
        <span className='text'>Help Center</span>
      </Link> */}
    </nav>
  </div>
}

export default UserNavbar;
