import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

export const initializeGoogleAnalytics = function () {
  ReactGA.initialize('UA-161514736-1', { testMode: process.env.NODE_ENV === 'test' });
};

export const getStrippedPathname = function (pathname) {
  if (pathname.startsWith('/user/project/')) {
    const indexOfLastSlash = pathname.lastIndexOf('/');
    return `/user/project${pathname.slice(indexOfLastSlash)}`;
  }
  return pathname;
}

/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */
export const withTracker = (WrappedComponent, trackLocationInsteadOfPath = false) => {
  const trackPage = page => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => {
      if (!trackLocationInsteadOfPath) {
        trackPage(props.match.path);
      }
    }, [props.match.path]);

    useEffect(() => {
      if (trackLocationInsteadOfPath) {
        const strippedPathname = getStrippedPathname(props.location.pathname);
        trackPage(strippedPathname);
      }
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
}

const trackEventFactory = category => {
  return (action, label, value) => {
    // console.log('event tracked', { category, action, label, value });
    // console.log('event tracked', category, action, label, value);  // For Safari
    ReactGA.event({
      category,
      action,
      label,
      value
    });
  };
}

/* @params (action) */
export const trackNavbarEvent = trackEventFactory('Navbar');

/* @params (action) */
export const trackUserNavbarEvent = trackEventFactory('User Navbar');

/* @params (action) */
export const trackCourseDashboardNavbarEvent = trackEventFactory('Course Dashboard Navbar');

/* @params (action) */
export const trackProjectNavbarEvent = trackEventFactory('Project Navbar');

/* @params (action, label, value) */
export const trackPlanEvent = trackEventFactory('Plan');

/* @params (action, label, value) */
export const trackCreateEvent = trackEventFactory('Create');

/* @params (action, label, value) */
export const trackStoryboardEvent = trackEventFactory('Storyboard');

/* @params (action, label, value) */
export const trackDesignEvent = trackEventFactory('Design');

/* @params (action, label, value) */
export const trackDeliverEvent = trackEventFactory('Deliver');

/* @params (action, label, value) */
export const trackDemoPortalEvent = trackEventFactory('Demo Portal');

/* @params (action, label, value) */
export const trackCoursesEvent = trackEventFactory('Courses');

/* @params (action, label, value) */
export const trackProjectsEvent = trackEventFactory('Projects');

/* @params (action, label, value) */
export const trackCompletionReportEvent = trackEventFactory('Completion Report');

/* @params (action, label, value) */
export const trackMessagesEvent = trackEventFactory('Messages');

/* @params (action, label, value) */
export const trackMessageDetailViewEvent = trackEventFactory('Message Detail View');

/* @params (action, label, value) */
export const trackStudentAssignmentsEvent = trackEventFactory('Assignments (Student)');

/* @params (action, label, value) */
export const trackPreslyFeedbackModalEvent = trackEventFactory('Presly Feedback Modal');

/* @params (action, label, value) */
export const trackCourseDashboardStudentsEvent = trackEventFactory('Students (Course Dashboard)');

/* @params (action, label, value) */
export const trackPracticeSessionDetailViewEvent = trackEventFactory('Practice Session Detail View');

/* @params (action, label, value) */
export const trackProjectDetailViewEvent = trackEventFactory('Project Detail View');

/* @params (action, label, value) */
export const trackFeedbackModalEvent = trackEventFactory('Feedback Modal');

/* @params (action, label, value) */
export const trackCourseDashboardAssignmentsEvent = trackEventFactory('Assignments (Course Dashboard)');

/* @params (action, label, value) */
export const trackAssignmentFormEvent = trackEventFactory('Assignment Form');

/* @params (action, label, value) */
export const trackCourseDashboardCustomizeContentEvent = trackEventFactory('Customize Content (Course Dashboard)');

/* @params (action, label, value) */
export const trackCourseDashboardSettingsEvent = trackEventFactory('Settings (Course Dashboard)');

/* @params (action, label, value) */
export const trackAccountSettingsEvent = trackEventFactory('Account Settings');

/* @params (action, label, value) */
export const trackSetUpAccountEvent = trackEventFactory('Set Up Account');

/* @params (action, label, value) */
export const trackSetUpStudentEvent = trackEventFactory('Set Up Student');

/* @params (action, label, value) */
export const trackConsentFormEvent = trackEventFactory('Consent Form');

/* @params (action, label, value) */
export const trackStudyRecruitmentEvent = trackEventFactory('Study Recruitment');

// @param <value> should be in milliseconds.
export const trackFeedbackBotTiming = function (variable, value) {
  ReactGA.timing({
    category: 'Feedback Bot',
    variable,
    value
  });
}
