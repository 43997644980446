import { combineReducers } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import checklistsAPIFactory from '../../../api/projects/ChecklistsAPIFactory';

const checklistSliceFactory = name => createSlice({
  name,
  initialState: {
    all: [],
    updateError: false
  },
  reducers: {
    checkItem(state, action) {
      state.updateError = false;
      let checklist = state.all.find(checklist => checklist.projectId === action.payload.projectId)
      if (checklist) {
        checklist[action.payload.item] = true;
      } else {
        checklist = {
          [action.payload.item]: true,
          projectId: action.payload.projectId
        };
        state.all.push(checklist);
      }
    },
    uncheckItem(state, action) {
      state.updateError = false;
      let checklist = state.all.find(checklist => checklist.projectId === action.payload.projectId)
      if (checklist) {
        checklist[action.payload.item] = false;
      } else {
        checklist = {
          [action.payload.item]: false,
          projectId: action.payload.projectId
        };
        state.all.push(checklist);
      }
    },
    fetchItems(state, action) {
      state.updateError = false;
      let checklistIndex = state.all.findIndex(checklist => checklist.projectId === action.payload.projectId);
      if (checklistIndex === -1) {
        state.all.push(action.payload);
      } else {
        state.all[checklistIndex] = action.payload;
      }
    },
    updateFailed(state) {
      state.updateError = true;
    },
    fetchFailed(state) {
      state.fetchError = true;
    },
  }
})

// <name> should be the resource name on the server for the given checklist
// E.g. For a checklist whose endpoint is "/api/projects/<id>/audience-field-checklist"
// <name> should be "audience-field-checklist"
export const apiChecklistSliceFactory = name => {
  let slice = checklistSliceFactory(name);
  let checklistsAPI = checklistsAPIFactory(name);
  return {
    checkItem: apiActionFactory(
      slice.actions.checkItem,
      checklistsAPI.checkItemOnServer,
      slice.actions.updateFailed,
    ),
    uncheckItem: apiActionFactory(
      slice.actions.uncheckItem,
      checklistsAPI.uncheckItemOnServer,
      slice.actions.updateFailed,
    ),
    fetchItems: apiActionFactory(
      slice.actions.fetchItems,
      checklistsAPI.fetchItemsFromServer,
      slice.actions.fetchFailed,
      true, true, false,
    ),
    reducer: slice.reducer
  }
}

// Each slice looks like this: {reducer: <>, checkItem: <>, uncheckItem: <>, fetchItems: <>}
export const audienceFieldSlice = apiChecklistSliceFactory('audience-field-checklists');
export const audienceKnowledgeSlice = apiChecklistSliceFactory('audience-knowledge-checklists');
export const deliverySlice = apiChecklistSliceFactory('delivery-checklists');
export const designSlice = apiChecklistSliceFactory('design-checklists');
export const messageSlice = apiChecklistSliceFactory('message-checklists');
export const projectObjectiveSlice = apiChecklistSliceFactory('project-objective-checklists');
export const storyboardChecklistSlice = apiChecklistSliceFactory('storyboard-checklists');

export default combineReducers({
  audienceFieldChecklists: audienceFieldSlice.reducer,
  audienceKnowledgeChecklists: audienceKnowledgeSlice.reducer,
  deliveryChecklists: deliverySlice.reducer,
  designChecklists: designSlice.reducer,
  messageChecklists: messageSlice.reducer,
  projectObjectiveChecklists: projectObjectiveSlice.reducer,
  storyboardChecklists: storyboardChecklistSlice.reducer
});
