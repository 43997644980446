import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  createPreslyFeedbackMessageOnServer,
  fetchPreslyFeedbackMessagesFromServer,
  markPreslyFeedbackMessageAsReadOnServer,
} from '../../../api/preslyFeedbackMessages/PreslyFeedbackMessagesAPI';
import { map } from 'lodash';

const preslyFeedbackMessagesSlice = createSlice({
  name: 'feedbackMessages',
  initialState: {
    all: []
  },
  reducers: {
    createPreslyFeedbackMessage(state, action) {
      state.createPreslyFeedbackError = false;
      state.all.push(action.payload);
    },
    createPreslyFeedbackMessageFailure(state) {
      state.createPreslyFeedbackError = true;
    },
    fetchPreslyFeedbackMessages(state, action) {
      state.fetchPreslyFeedbackMessagesError = false;
      let messagesWithNumericalDates = map(action.payload, message => {
        if (message.dateCreated) {
          message.dateCreated = (new Date(message.dateCreated)).valueOf();
        }
        return message;
      });
      state.all = messagesWithNumericalDates;
    },
    fetchPreslyFeedbackMessagesFailure(state) {
      state.fetchPreslyFeedbackMessagesError = true;
    },
    markPreslyFeedbackMessageAsRead(state, action) {
      state.markPreslyFeedbackMessageAsReadError = false;
      const feedbackMessage = state.all.find(feedbackMessage => feedbackMessage.id === action.payload.id)
      if (feedbackMessage) {
        feedbackMessage.read = true;
      }
    },
    markPreslyFeedbackMessageAsReadFailure(state) {
      state.markPreslyFeedbackMessageAsReadError = true;
    },
    // deletePreslyFeedbackMessage(state, action) {
    //   state.deleteError = false;
    //   state.all = state.all.filter(feedbackMessage => feedbackMessage.id !== action.payload.id)
    // },
    // deletePreslyFeedbackMessageFailure(state) {
    //   state.deleteError = true;
    // },
  }
})


export const createPreslyFeedbackMessage = apiActionFactory(
  preslyFeedbackMessagesSlice.actions.createPreslyFeedbackMessage,
  createPreslyFeedbackMessageOnServer,
  preslyFeedbackMessagesSlice.actions.createPreslyFeedbackMessageFailure,
  true
);

export const fetchPreslyFeedbackMessages = apiActionFactory(
  preslyFeedbackMessagesSlice.actions.fetchPreslyFeedbackMessages,
  fetchPreslyFeedbackMessagesFromServer,
  preslyFeedbackMessagesSlice.actions.fetchPreslyFeedbackMessagesFailure,
  true
);

export const markPreslyFeedbackMessageAsRead = apiActionFactory(
  preslyFeedbackMessagesSlice.actions.markPreslyFeedbackMessageAsRead,
  markPreslyFeedbackMessageAsReadOnServer,
  preslyFeedbackMessagesSlice.actions.markPreslyFeedbackMessageAsReadFailure
);

// export const deletePreslyFeedbackMessage = apiActionFactory(
//   preslyFeedbackMessagesSlice.actions.deletePreslyFeedbackMessage,
//   deletePreslyFeedbackMessageOnServer,
//   preslyFeedbackMessagesSlice.actions.deletePreslyFeedbackMessageFailure
// );

export default preslyFeedbackMessagesSlice.reducer;
