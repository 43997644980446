import Cookies from 'js-cookie';

class Authenticator {
  isAuthenticated() {
    return Cookies.get('isAuthenticated') === 'true';
  }
}

// This object will be a singleton.
export default new Authenticator();
