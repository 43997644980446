import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import './PreslyTippy.scss';

const PreslyTippy = ({ children, theme, ...props }) => {
    return <Tippy
        theme={theme || 'presly'}
        {...props}>
        {children}
    </Tippy>;
}

export default PreslyTippy;