import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

// Returns [course1, course2, ...]
const getCoursesUnsorted = state => state.courses.all || [];

export const getCourses = createSelector(
  getCoursesUnsorted,
  courses => sortBy(courses, 'id')
);

export const getActiveCourseId = state => state.courses.activeCourseId;
export const getActiveSectionId = state => state.courses.activeSectionId;
export const getActiveAssignmentId = state => state.courses.activeAssignmentId;

export const getActiveCourse = createSelector(
  [getCoursesUnsorted, getActiveCourseId],
  (courses, activeCourseId) => courses.find(course => course.id === activeCourseId)
);

export const getSectionsForActiveCourse = createSelector(
  getActiveCourse,
  course => course?.sections
);

export const getActiveSection = createSelector(
  [getSectionsForActiveCourse, getActiveSectionId],
  (sections, activeSectionId) => sections?.find(section => section.id === activeSectionId)
);
