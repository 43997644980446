// TODO add subject-specific data below.

export const EXAMPLE_PLAN_AUDIENCE_EXPECTATIONS_TEXT = {
    'UNSPECIFIED': "e.g. My audience expects me to give an overview of the Romantic period. They're hoping the presentation is well-researched and interesting, and they expect that it will follow a standard format. They already know a little bit about Romanticism, so they're hoping I go beyond surface-level information.",
    'HUMANITIES': "e.g. My audience expects me to give an overview of the Romantic period. They're hoping the presentation is well-researched and interesting, and they expect that it will follow a standard format. They already know a little bit about Romanticism, so they're hoping I go beyond surface-level information.",
    'SCIENCE': "e.g. My audience expects me to teach them something new about biology. They're hoping the presentation is well-researched and interesting, and they expect that it will follow a standard format. They don't know much about whales outside of what they've learned in school, so they're hoping to learn something new and useful.",
    'ENGINEERING': "e.g. My audience expects me to teach them something new about sorting algorithms. They're hoping the presentation is well-researched and interesting, and they expect that it will follow a standard format. They don't know much about sorting algorithms, so they're hoping I give a high-level overview before we get into the more intense stuff.",
}

export const EXAMPLE_PLAN_MESSAGE_TEXT = {
    'UNSPECIFIED': 'e.g. The Romantic period was a reaction to the Age of Reason. The Romantics praised nature and the innocence of children, and they emphasized the aesthetic notions of the sublime and the picturesque.',
    'HUMANITIES': 'e.g. The Romantic period was a reaction to the Age of Reason. The Romantics praised nature and the innocence of children, and they emphasized the aesthetic notions of the sublime and the picturesque.',
    'SCIENCE': 'e.g. Whales are a group of marine mammals that live in every ocean on earth. Some species have teeth and eat large animals, while other species use baleen plates to eat shrimp and krill. After whales were overhunted in the early twentieth century, the International Whaling Commission was set up in 1946, resulting in a much brighter future for whales today.',
    'ENGINEERING': 'e.g. Sorting algorithms are judged by their temporal and spatial efficiencies. Computer scientists must understand the tradeoffs each sorting algorithm makes in order to choose the best algorithm for every situation.',
}

// The <videoId> property refers to the video id on YouTube.
export const EXAMPLE_CREATE_FORMAT_VIDEO = {
    'UNSPECIFIED': {
        'classic': { videoId: 'Ucp0TTmvqOE', start: '6783', end: '8707' },
        'ted': { videoId: 'GXy__kBVq1M', start: '10' },
        'lightning': { videoId: 'O2_A0ViX2Pg' },
        'pechakucha': { videoId: 'FplqVvtUjbc', start: '4' },
    },
    'HUMANITIES': {
        'classic': { videoId: 'Ucp0TTmvqOE', start: '6783', end: '8707' },
        'ted': { videoId: 'GXy__kBVq1M', start: '10' },
        'lightning': { videoId: 'O2_A0ViX2Pg' },
        'pechakucha': { videoId: 'FplqVvtUjbc', start: '4' },
    },
    'SCIENCE': {
        'classic': { videoId: 'Ucp0TTmvqOE', start: '6783', end: '8707' },
        'ted': { videoId: 'GXy__kBVq1M', start: '10' },
        'lightning': { videoId: 'O2_A0ViX2Pg' },
        'pechakucha': { videoId: 'FplqVvtUjbc', start: '4' },
    },
    'ENGINEERING': {
        'classic': { videoId: 'Ucp0TTmvqOE', start: '6783', end: '8707' },
        'ted': { videoId: 'GXy__kBVq1M', start: '10' },
        'lightning': { videoId: 'O2_A0ViX2Pg' },
        'pechakucha': { videoId: 'FplqVvtUjbc', start: '4' },
    },
}

export const EXAMPLE_DELIVER_TRAITS_VIDEO = {
    'UNSPECIFIED': { videoId: '8KkKuTCFvzI', start: '12' },
    'HUMANITIES': { videoId: '8KkKuTCFvzI', start: '12' },
    'SCIENCE': { videoId: '8KkKuTCFvzI', start: '12' },
    'ENGINEERING': { videoId: '8KkKuTCFvzI', start: '12' },
}

export const EXAMPLE_DELIVER_METHOD_VIDEO = {
    'UNSPECIFIED': { videoId: 'qp0HIF3SfI4', start: '275' },
    'HUMANITIES': { videoId: 'qp0HIF3SfI4', start: '275' },
    'SCIENCE': { videoId: 'qp0HIF3SfI4', start: '275' },
    'ENGINEERING': { videoId: 'qp0HIF3SfI4', start: '275' },
}
