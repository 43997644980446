import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const createFeedbackMessageOnServer = async function (payload) {
    if ((!payload.hasOwnProperty('project') &&
        !payload.hasOwnProperty('slideshow') &&
        !payload.hasOwnProperty('practiceSession')) ||
        !payload.hasOwnProperty('text')) {
        throw new Error('Missing Parameter: createFeedbackMessageOnServer requires that the payload contain <text> and either <project>, <slideshow>, or <practiceSession>');
    }

    // Replace <project> with <projectId> for the update if necessary.
    if (payload.project) {
        payload.projectId = payload.project;
        delete payload.project;
    }

    // Replace <slideshow> with <slideshowId> for the update if necessary.
    if (payload.slideshow) {
        payload.slideshowId = payload.slideshow;
        delete payload.slideshow;
    }

    // Replace <practiceSession> with <practiceSessionId> for the update if necessary.
    if (payload.practiceSession) {
        payload.practiceSessionId = payload.practiceSession;
        delete payload.practiceSession;
    }

    try {
        let response = await axios.post('/api/feedback-messages', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No feedback message object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/feedback-message failed'));
    }
};

export const fetchAllFeedbackFromServer = async function () {
    try {
        const response = await axios.get('/api/feedback-messages');
        return response.data || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching all feedback'));
    }
};

export const fetchProjectFeedbackFromServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: fetchProjectFeedbackFromServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.get('/api/projects/' + payload.id + '/feedback');
        return response.data?.feedbackMessages || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching project feedback'));
    }
};

export const fetchSlideshowFeedbackFromServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: fetchSlideshowFeedbackFromServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.get('/api/slideshows/' + payload.id + '/feedback');
        return response.data?.feedbackMessages || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching slideshow feedback'));
    }
};

export const fetchPracticeSessionFeedbackFromServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: fetchPracticeSessionFeedbackFromServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.get('/api/practice-sessions/' + payload.id + '/feedback');
        return response.data?.feedbackMessages || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching practice session feedback'));
    }
};

export const markFeedbackMessageAsReadOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: markFeedbackMessageAsReadOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.post(`/api/feedback-messages/${payload.id}/mark_as_read`);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No feedback message object returned');
        }
    } catch (error) {
        throw new Error(`Error: POST /api/feedback-messages/${payload.id}/mark_as_read failed`);
    }
};
