import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchAllSlideshowsFromServer = async function (payload) {
    try {
        let response = await axios.get('/api/slideshows');
        return { slideshows: response.data || [], currentUserId: payload.currentUserId };
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching slideshows'));
    }
};

export const createSlideshowOnServer = async function (payload) {
    if (!payload.hasOwnProperty('project') || !payload.hasOwnProperty('name') || !payload.hasOwnProperty('slideshow')) {
        throw new Error('Missing Parameter: createSlideshowOnServer requires that the payload contain <project>, <name>, and <slideshow>');
    }

    // Replace <project> with <projectId> for the post request.
    payload.projectId = payload.project;
    delete payload.project;

    try {
        let response = await axios.post('/api/slideshows', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/slideshows failed'));
    }
};

export const updateSlideshowOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: updateSlideshowOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.patch('/api/slideshows/' + payload.id, payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PATCH /api/slideshows/' + payload.id + ' failed'));
    }
};

export const deleteSlideshowOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteSlideshowOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/slideshows/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/slideshows/' + payload.id + ' failed'));
    }
};
