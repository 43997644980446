import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import IconWithTooltip from './IconWithTooltip';

const QuestionIcon = ({ content, className = '', ...props }) => {
  return <IconWithTooltip
    icon={faQuestionCircle}
    content={content}
    className={className}
    {...props}
  />
}

export default QuestionIcon;
