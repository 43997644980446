import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import './Footer.scss';

function Footer() {
  return (
    <footer className='container-fluid py-5' data-testid='footer'>
      <div className='row'>
        <div className='col-12 col-sm-10 offset-sm-1 col-xl-8 offset-xl-2'>
          <div className='row'>
            <div className='col-12 order-2 mt-4 text-center col-lg-6 order-lg-1 mt-lg-0 text-lg-left'>
              <FontAwesomeIcon icon={faCopyright} aria-label='copyright' /> Presly 2021. All rights reserved.
            </div>
            <div className='col-12 order-1 text-center col-lg-6 order-lg-2 text-lg-right'>
              Made with <FontAwesomeIcon icon={faHeart} aria-label='heart' /> in Columbus, Ohio.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
