import React from 'react';
import { isPlainObject } from 'lodash';
import { isSafari } from 'react-device-detect';
import { DynamicImageImport } from './ModernImage';

// Returns a map of videos to use depending on the browser.
// The provided <videoPath> should be relative to the assets/imgs folder.
export const getModernVideoSrc = (videoPath, useWebm = true, useGifFallback = true) => {
  return {
    mp4: DynamicImageImport(`${videoPath}Optimized.mp4`),
    webm: useWebm && DynamicImageImport(`${videoPath}Optimized.webm`),
    gif: useGifFallback && DynamicImageImport(`${videoPath}.gif`),
  }
}

const ModernVideo = ({ src, alt = '', ...props }) => {
  if (isPlainObject(src)) {
    // I.e. src is a map of video sources that work on different browsers.
    // TODO see if I need to change back to a video tag
    if (!isSafari) {
      return <video
        alt={alt}
        autoPlay={true}
        loop={true}
        muted={true}
        preload='metadata'
        {...props}
      >
        {/* Chrome */}
        {!!src.webm &&
          <source src={src.webm} type='video/webm' />
        }
        {/* Most browsers */}
        {!!src.mp4 &&
          <source src={src.mp4} type='video/mp4' />
        }
        Your browser does not support the video tag.
      </video>
    } else if (src.gif) {
      /* Safari should be able to show mp4s, but I've been
          having issues with it, so I'm just going to use a gif for now */
      return <img src={src.gif} alt={alt} {...props} />
    }
    // TODO show a static image maybe? Or a better error.
    return <>Your browser does not support the video tag.</>;
  }
  throw Error('ModernVideo `src` should be a modern video source object (see `getModernVideoSrc`)')
}

export default ModernVideo;
