import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchUserFromServer,
  setFirstNameOnServer,
  setLastNameOnServer,
  setRoleOnServer,
  setIsIndependentUserOnServer,
  setConsentsToSharingSlideshowsOnServer,
  setIsParticipatingInTheStudyOnServer,
  deleteUserOnServer
} from '../../../api/user/UserAPI';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    current: {},
    anonymousUserHasViewedWelcomeModal: false,
  },
  reducers: {
    fetchUser(state, action) {
      state.fetchUserError = false;
      state.current = action.payload.user;
      if (state.current) {
        // If the user is logged, we should reset the Welcome Modal
        // for anonymous users. That way, if someone on this
        // computer tries to use Presly without an account, we
        // will show them the Welcome Modal, which will tell them
        // that they need to sign in if they want their data to be
        // saved on the server.
        state.anonymousUserHasViewedWelcomeModal = false;
      }
    },
    fetchUserFailure(state) {
      state.fetchUserError = true;
    },
    setFirstName(state, action) {
      state.setFirstNameError = false;
      state.current.firstName = action.payload.firstName;
    },
    setFirstNameFailure(state) {
      state.setFirstNameError = true;
    },
    setLastName(state, action) {
      state.setLastNameError = false;
      state.current.lastName = action.payload.lastName;
    },
    setLastNameFailure(state) {
      state.setLastNameError = true;
    },
    setRole(state, action) {
      state.setRoleError = false;
      state.current.student = action.payload.student;
      state.current.instructor = action.payload.instructor;
    },
    setRoleFailure(state) {
      state.setRoleError = true;
    },
    setIsIndependentUser(state, action) {
      state.setIsIndependentUserError = false;
      state.current.student = action.payload.student;
    },
    setIsIndependentUserFailure(state) {
      state.setIsIndependentUserError = true;
    },
    setConsentsToSharingSlideshows(state, action) {
      state.setConsentsToSharingSlideshowsError = false;
      state.current.student = action.payload.student;
    },
    setConsentsToSharingSlideshowsFailure(state) {
      state.setConsentsToSharingSlideshowsError = true;
    },
    setIsParticipatingInTheStudy(state, action) {
      state.setIsParticipatingInTheStudyError = false;
      state.current.student = action.payload.student;
    },
    setIsParticipatingInTheStudyFailure(state) {
      state.setIsParticipatingInTheStudyError = true;
    },
    deleteUser(state) {
      state.fetchUserError = false;
      state.current = {};
    },
    deleteUserFailure(state) {
      state.fetchUserError = true;
    },
    decrementNumberOfUnreadMessages(state) {
      state.current.numberOfUnreadMessages -= 1;
    },
    setAnonymousUserHasViewedWelcomeModal(state, action) {
      state.anonymousUserHasViewedWelcomeModal = action.payload.anonymousUserHasViewedWelcomeModal;
    },
  }
})


export const fetchUser = apiActionFactory(
  userSlice.actions.fetchUser,
  fetchUserFromServer,
  userSlice.actions.fetchUserFailure,
  true
);

export const setFirstName = apiActionFactory(
  userSlice.actions.setFirstName,
  setFirstNameOnServer,
  userSlice.actions.setFirstNameFailure
);

export const setLastName = apiActionFactory(
  userSlice.actions.setLastName,
  setLastNameOnServer,
  userSlice.actions.setLastNameFailure
);

export const setRole = apiActionFactory(
  userSlice.actions.setRole,
  setRoleOnServer,
  userSlice.actions.setRoleFailure,
  true
);

export const setIsIndependentUser = apiActionFactory(
  userSlice.actions.setIsIndependentUser,
  setIsIndependentUserOnServer,
  userSlice.actions.setIsIndependentUserFailure,
  true
);

export const setConsentsToSharingSlideshows = apiActionFactory(
  userSlice.actions.setConsentsToSharingSlideshows,
  setConsentsToSharingSlideshowsOnServer,
  userSlice.actions.setConsentsToSharingSlideshowsFailure,
  true
);

export const setIsParticipatingInTheStudy = apiActionFactory(
  userSlice.actions.setIsParticipatingInTheStudy,
  setIsParticipatingInTheStudyOnServer,
  userSlice.actions.setIsParticipatingInTheStudyFailure,
  true
);

export const deleteUser = apiActionFactory(
  userSlice.actions.deleteUser,
  deleteUserOnServer,
  userSlice.actions.deleteUserFailure,
  true
);

export const { decrementNumberOfUnreadMessages, setAnonymousUserHasViewedWelcomeModal } = userSlice.actions;

export default userSlice.reducer;
