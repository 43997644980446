import { combineReducers } from 'redux';
import userReducer from './features/user/userSlice';
import projectsReducer from './features/projects/projectsSlice';
import coursesReducer from './features/courses/coursesSlice';
import studentsReducer from './features/students/studentsSlice';
import assignmentsReducer from './features/assignments/assignmentsSlice';
import assignmentAttachmentsReducer from './features/assignmentAttachments/assignmentAttachmentsSlice';
import checklistsReducer from './features/projects/checklistSlices';
import storyboardTilesReducer from './features/projects/storyboardTilesSlice';
import slideshowsReducer from './features/slideshows/slideshowsSlice';
import practiceSessionsReducer from './features/practiceSessions/practiceSessionsSlice';
import feedbackMessagesReducer from './features/feedbackMessages/feedbackMessagesSlice';
import customPedagogyConfigurationsReducer from './features/customPedagogyConfigurations/customPedagogyConfigurationsSlice';
import preslyFeedbackMessagesReducer from './features/preslyFeedbackMessages/preslyFeedbackMessagesSlice';

const appReducer = combineReducers({
  user: userReducer,
  projects: projectsReducer,
  courses: coursesReducer,
  students: studentsReducer,
  assignments: assignmentsReducer,
  assignmentAttachments: assignmentAttachmentsReducer,
  checklists: checklistsReducer,
  storyboardTiles: storyboardTilesReducer,
  slideshows: slideshowsReducer,
  practiceSessions: practiceSessionsReducer,
  feedbackMessages: feedbackMessagesReducer,
  customPedagogyConfigurations: customPedagogyConfigurationsReducer,
  preslyFeedbackMessages: preslyFeedbackMessagesReducer,
});

// Dispatch this action when logging out, etc... in order to clear the Redux store.
const CLEAR_REDUX_STORE_ACTION = { type: 'CLEAR_REDUX_STORE' };

const rootReducer = (state, action) => {
  if (action.type === CLEAR_REDUX_STORE_ACTION.type) {
    state = undefined;
  }

  return appReducer(state, action);
}

export const clearReduxStore = () => (CLEAR_REDUX_STORE_ACTION);
export default rootReducer;