import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { trackNavbarEvent } from '../../../assets/utils/GoogleAnalytics';
import './NavbarSelectors.scss';


const ProjectSelector = ({ projects, activeProject, onSelect }) => {
  const location = useLocation();
  const selectorRef = useRef(null);

  function handleWindowMouseClick(event) {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      onSelect();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleWindowMouseClick);
    return () => {
      document.removeEventListener('click', handleWindowMouseClick);
    }
  });

  const getProjectURL = project => {
    let finalSlashIndex = location.pathname.lastIndexOf('/', location.pathname.length - 2);
    let pageName = location.pathname.slice(finalSlashIndex);
    return '/user/project/' + project.id + pageName;
  }

  const trackProjectSelectionEvent = project => {
    if (project.id === activeProject.id) {
      trackNavbarEvent('Selected Current Project');
    } else {
      trackNavbarEvent('Selected Other Project');
    }
    onSelect();
  }

  const openProjectsPage = () => {
    trackNavbarEvent('Clicked Add Project');
    onSelect();
  }

  const getSelectProjectBtn = project => {
    return <>
      <Link
        className={'clear-link-styling select-project-btn no-select' + (project.id === activeProject.id ? ' active' : '')}
        onClick={() => trackProjectSelectionEvent(project)}
        to={getProjectURL(project)}
      >
        <span className='float-left mr-5'>
          {project.title}
        </span>
        {project.id === activeProject.id &&
          <span className='float-right ml-4'>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        }
      </Link>
    </>;
  }

  return (
    <div ref={selectorRef} className='project-selector container-fluid'>
      {projects.map(project =>
        <div key={`project-${project.id}`} className='row'>
          <div className='col-12'>
            {getSelectProjectBtn(project)}
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-12'>
          <div className='divider'></div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Link
            className='clear-link-styling add-project-btn-nav no-select'
            onClick={openProjectsPage}
            to='/user/projects'
          >
            <FontAwesomeIcon icon={faPlus} className='mr-3' />
            Add Project
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProjectSelector;
