import axios from 'axios';
import { pick } from 'lodash';
import { getMeaningfulErrorMessage } from '../../redux/utils';

///////////// TILES - CREATE, DELETE, UPDATE //////////////
export const createTileOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('position')) {
        throw new Error('Missing Parameter: createTileOnServer requires that the payload contain <projectId> and <position>');
    }

    try {
        let response = await axios.post('/api/projects/' + payload.projectId + '/storyboard-tiles', { position: payload.position });
        if (response.data.tile) {
            return response.data.tile;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/projects/' + payload.projectId + '/storyboard-tiles failed'));
    }
};

export const deleteTileOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteTileOnServer requires that the payload contain <projectId> and <id>');
    }

    try {
        let response = await axios.delete('/api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.id);
        let data = response.data;

        if (data.status === 'ok') {
            return data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.id + ' failed'));
    }
};

export const setTakeawayOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('id') || !payload.hasOwnProperty('takeaway')) {
        throw new Error('Missing Parameter: setTakeawayOnServer requires that the payload contain <projectId>, <id>, and <takeaway>');
    }

    try {
        let response = await axios.put('/api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.id, payload);
        if (response.data.tile) {
            return response.data.tile;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PUT /api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.id + ' failed'));
    }
};

///////////// VISUALS - CREATE, DELETE, UPDATE //////////////
// @param (optional) include <text> in the payload
export const createVisualOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('tileId')) {
        throw new Error('Missing Parameter: createVisualOnServer requires that the payload contain <projectId> and <tileId>');
    }

    try {
        let response = await axios.post('/api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.tileId + '/visuals', payload);
        if (response.data.visual) {
            return response.data.visual;
        } else {
            throw new Error('Error: No visual object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.tileId + '/visuals failed'));
    }
};

export const deleteVisualOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('tileId') || !payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteVisualOnServer requires that the payload contain <projectId>, <tileId>, and <id>');
    }

    try {
        let response = await axios.delete('/api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.tileId + '/visuals/' + payload.id);
        let data = response.data;

        if (data.status === 'ok') {
            return data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.tileId + '/visuals/' + payload.id + ' failed'));
    }
};

export const setTileVisualTextOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('tileId') || !payload.hasOwnProperty('id') || !payload.hasOwnProperty('text')) {
        throw new Error('Missing Parameter: setTileVisualTextOnServer requires that the payload contain <projectId>, <tileId>, <id>, and <text>');
    }

    try {
        let response = await axios.put('/api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.tileId + '/visuals/' + payload.id, { text: payload.text });
        if (response.data.visual) {
            return response.data.visual;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PUT /api/projects/' + payload.projectId + '/storyboard-tiles/' + payload.tileId + '/visuals/' + payload.id + ' failed'));
    }
}


///////////// FETCH / PUSH / REORDER //////////////
export const fetchTilesFromServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId')) {
        throw new Error('Missing Parameter: updateTileOnServer requires that the payload contain <projectId>');
    }

    try {
        let response = await axios.get('/api/projects/' + payload.projectId + '/storyboard-tiles');
        return { tiles: response.data.tiles || [] };
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching storyboard tiles'));
    }
};

export const createMultipleTilesOnServer = async function (payload) {
    if (!payload.hasOwnProperty('tiles')) {
        throw new Error('Missing Parameter: createMultipleTilesOnServer requires that the payload contain <projectId> and <tiles>');
    }

    try {
        let response = await axios.post('/api/projects/' + payload.projectId + '/storyboard-tiles', { tiles: payload.tiles });
        if (response.data.tiles) {
            return { tiles: response.data.tiles };
        } else {
            throw new Error('Error: Failed to update the storyboard');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/projects/' + payload.projectId + '/storyboard-tiles failed'));
    }
};

export const deleteAllTilesOnServer = async function (payload) {
    if (!payload.hasOwnProperty('projectId')) {
        throw new Error('Missing Parameter: deleteAllTilesOnServer requires that the payload contain <projectId>');
    }

    try {
        let response = await axios.delete('/api/projects/' + payload.projectId + '/storyboard-tiles');
        if (response.data.status === 'ok') {
            return { projectId: payload.projectId };
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DEL /api/projects/' + payload.projectId + '/storyboard-tiles failed'));
    }
};

export const setTilePositionsOnServer = async function (payload) {
    if (!payload.hasOwnProperty('tiles')) {
        throw new Error('Missing Parameter: setTilePositionsOnServer requires that the payload contain <projectId> and <tiles>');
    }

    let tiles = payload.tiles.map(tile => pick(tile, ['id', 'position']));

    try {
        let response = await axios.put('/api/projects/' + payload.projectId + '/storyboard-tiles', { tiles });
        if (response.data.tiles) {
            return { tiles: response.data.tiles };
        } else {
            throw new Error('Error: Failed to update the tiles');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PUT /api/projects/' + payload.projectId + '/storyboard-tiles failed'));
    }
};
