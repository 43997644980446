import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { getUser } from '../user/userSelector';

// Returns [project1, project2, ...]
const getProjectsUnsorted = state => state.projects.all || [];

const getProjectsForCurrentUserUnsorted = createSelector(
  [getProjectsUnsorted, getUser],
  (projects, currentUser) => projects.filter(project => project.user?.id === currentUser.id)
);

// Returns all projects belonging to the current user,
// sorted by id.
export const getProjects = createSelector(
  getProjectsForCurrentUserUnsorted,
  projects => sortBy(projects, 'id')
);

export const getActiveProjectId = state => state.projects.activeProjectId

export const getActiveProject = createSelector(
  [getProjectsUnsorted, getActiveProjectId],
  (projects, activeProjectId) => projects.find(project => project.id === activeProjectId)
);
