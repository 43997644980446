import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchStudentsFromServer
} from '../../../api/students/StudentsAPI';

const studentsSlice = createSlice({
  name: 'students',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    populateStudents(state, action) {
      const studentsWithNumericalProjectDates = map(action.payload, student => {
        student.projects = map(student.projects, project => {
          project.lastActivity = (new Date(project.lastActivity)).valueOf();
          return project;
        });
        return student;
      });
      const studentsAnnotated = map(studentsWithNumericalProjectDates, student => {
        student.fullNameLowerCase = student.firstName.toLowerCase() + ' ' + student.lastName.toLowerCase();
        return student;
      });
      state.all = studentsAnnotated;
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchStudentsFailure(state) {
      state.isFetching = false;
      state.fetchError = true;
    },
  }
});

export const fetchStudentsInActiveCourse = apiActionFactory(
  studentsSlice.actions.populateStudents,
  fetchStudentsFromServer,
  studentsSlice.actions.fetchStudentsFailure,
  true
);

export default studentsSlice.reducer;
