import colors from '../styles/_colors.scss';

export const preslySummary = 'Presly teaches presentation skills through an interactive online interface. Its pedagogy is based on the latest in communications research.';
export const preslySummaryLong = 'Presly teaches presentation skills by providing custom advice based on the latest in communications research.';

export const ANONYMOUS_USER_PROJECT_ID = -1;

const REACT_SELECT_THEME_COLORS = {
    'primary': colors.themePrimaryColorLight,
    'primary75': colors.themePrimaryColorLightFaint,
    'primary50': colors.themePrimaryColorLightFaint50,
    'primary25': colors.themePrimaryColorLightFaint25,
    // 'danger': colors.failureColor,
    // 'dangerLight': colors.badColor,
    'neutral0': colors.themeSecondaryColor0,
    'neutral5': colors.themeSecondaryColor5,
    'neutral10': colors.themeSecondaryColor10,
    'neutral20': colors.themeSecondaryColor20,
    'neutral30': colors.themeSecondaryColor30,
    'neutral40': colors.themeSecondaryColor40,
    'neutral50': colors.themeSecondaryColor50,
    'neutral60': colors.themeSecondaryColor60,
    'neutral70': colors.themeSecondaryColor70,
    'neutral80': colors.themeSecondaryColor80,
    'neutral90': colors.themeSecondaryColor90,
}

export const getReactSelectTheme = theme => ({
    ...theme,
    borderRadius: 4,
    colors: {
        ...theme.colors,
        ...REACT_SELECT_THEME_COLORS
    },
});
