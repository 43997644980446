import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';  // Goes before AppRoutes and other components (for cascading purposes)
import { ToastContainer, Slide } from 'react-toastify';
import AppRoutes from './routes/routes';
import ScrollToTop from './routes/ScrollToTop';
import Authenticator from './assets/utils/Authenticator';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from './components/common/ErrorBoundaryFallback';
import { initializeGoogleAnalytics } from './assets/utils/GoogleAnalytics';
import { preslySummaryLong } from './assets/constants/constants';
import { fetchSlideshows } from './redux/features/slideshows/slideshowsSlice';
import { fetchPracticeSessions } from './redux/features/practiceSessions/practiceSessionsSlice';
import { fetchProjects } from './redux/features/projects/projectsSlice';
import { fetchCourses } from './redux/features/courses/coursesSlice';
import { fetchAllAssignments } from './redux/features/assignments/assignmentsSlice';
import { fetchAllAssignmentAttachments } from './redux/features/assignmentAttachments/assignmentAttachmentsSlice';
import { fetchCustomPedagogyConfigurations } from './redux/features/customPedagogyConfigurations/customPedagogyConfigurationsSlice';
import { fetchUser } from './redux/features/user/userSlice';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthenticated: Authenticator.isAuthenticated() };

    if (Authenticator.isAuthenticated()) {
      this.fetchData(props);
    }

    initializeGoogleAnalytics();
  }

  async fetchData(props) {
    const response = await props.fetchUser();
    if (response?.user) {
      // TODO if an anonymous project exists, then we should maybe push it to the server.

      props.fetchCourses();
      props.fetchCustomPedagogyConfigurations();
      // We feed `fetchProjects` the currentUserId so that Redux knows
      // which projects in the <projects> list belong to students and 
      // which belong, potentially, to the current instructor.
      // Since `fetchProjects` only fetches the projects that the current
      // user owns, Redux has to know which projects belong to the
      // current user (i.e. which projects to replace with the projects
      // returned by `fetchProjects`)
      props.fetchProjects({ currentUserId: response.user.id });
      props.fetchSlideshows({ currentUserId: response.user.id });
      props.fetchPracticeSessions({ currentUserId: response.user.id });
      // We need to fetch all assignments here -- even for instructors --
      // because the project form needs them.
      props.fetchAllAssignments();
      props.fetchAllAssignmentAttachments();
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className='App'>
          <Helmet>
            <meta name='description' content={preslySummaryLong} />
          </Helmet>
          <ErrorBoundary fallback={ErrorBoundaryFallback({ className: 'py-2 fixed-top h4' })}>
            <Navbar isAuthenticated={this.state.isAuthenticated} />
          </ErrorBoundary>
          <ErrorBoundary fallback={ErrorBoundaryFallback({ className: 'min-height-page-container pt-5 my-0' })}>
            <AppRoutes isAuthenticated={this.state.isAuthenticated} />
          </ErrorBoundary>
          <Footer />
          <ErrorBoundary fallback={ErrorBoundaryFallback()}>
            <ToastContainer
              position='top-center'
              autoClose={5000}
              transition={Slide}
              newestOnTop={false}
              hideProgressBar
              closeOnClick
              pauseOnFocusLoss
              pauseOnHover
              limit={3}
            />
          </ErrorBoundary>
        </div>
      </Router>
    );
  }
}

export default connect(
  null,
  {
    fetchSlideshows,
    fetchPracticeSessions,
    fetchProjects,
    fetchCourses,
    fetchUser,
    fetchAllAssignments,
    fetchAllAssignmentAttachments,
    fetchCustomPedagogyConfigurations
  }
)(App);
