import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchAllAssignmentAttachmentsFromServer = async function () {
    try {
        let response = await axios.get('/api/assignment-attachments');
        return response.data || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching assignment attachments'));
    }
};

export const createAssignmentAttachmentOnServer = async function (payload) {
    if (!payload.hasOwnProperty('assignment') || !payload.hasOwnProperty('name') || !payload.hasOwnProperty('attachment')) {
        throw new Error('Missing Parameter: createAssignmentAttachmentOnServer requires that the payload contain <assignment>, <name>, and <attachment>');
    }

    try {
        let response = await axios.post('/api/assignment-attachments', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/assignment-attachments failed'));
    }
};

// export const renameAssignmentAttachmentOnServer = async function (payload) {
//     if (!payload.hasOwnProperty('id') || !payload.hasOwnProperty('name')) {
//         throw new Error('Missing Parameter: renameAssignmentAttachmentOnServer requires that the payload contain <id> and <name>');
//     }

//     try {
//         let response = await axios.patch('/api/assignment-attachments/' + payload.id, { name: payload.name });
//         if (response.data) {
//             return response.data;
//         } else {
//             throw new Error('Error: Unexpected server response');
//         }
//     } catch (error) {
//         throw new Error(getMeaningfulErrorMessage(error, 'Error: PATCH /api/assignment-attachments/' + payload.id + ' failed'));
//     }
// };

export const deleteAssignmentAttachmentOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteAssignmentAttachmentOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/assignment-attachments/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/assignment-attachments/' + payload.id + ' failed'));
    }
};

export const deleteAssignmentAttachmentFileOnServer = async function (payload) {
    if (!payload.hasOwnProperty('assignment') || !payload.hasOwnProperty('name')) {
        throw new Error('Missing Parameter: deleteAssignmentAttachmentFileOnServer requires that the payload contain <assignment> and <name>');
    }

    try {
        let response = await axios.delete('/api/assignment-attachments/cancel_upload', { data: payload });
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/assignment-attachments/cancel_upload failed'));
    }
};
