import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { throttle } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createPreslyFeedbackMessage } from '../../redux/features/preslyFeedbackMessages/preslyFeedbackMessagesSlice';
import QuestionIcon from './QuestionIcon';
import { trackPreslyFeedbackModalEvent } from '../../assets/utils/GoogleAnalytics';
import './PreslyFeedbackModal.scss';
import './FeedbackForm.scss';


const trackTypedFeedback = () => trackPreslyFeedbackModalEvent('Typed Feedback');
const trackTypedFeedbackThrottled = throttle(trackTypedFeedback, 60000, { trailing: false });


const PreslyFeedbackModal = ({ modalIsShowing, handleModalClose }) => {
  const dispatch = useDispatch();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const submitFeedback = async () => {
    if (!feedbackMessage) {
      toast.error('Error: Please type your feedback into the box below.');
      return;
    }

    setSendingMessage(true);
    const response = await dispatch(createPreslyFeedbackMessage({ text: feedbackMessage }));
    setSendingMessage(false);
    if (response) {
      setFeedbackSubmitted(true);
    }
  }

  return <Modal
    onHide={handleModalClose}
    show={modalIsShowing}
    className='presly-feedback-modal'
    centered
    size='lg'
  >
    <Modal.Header className='presly-feedback-modal-header'>
      Send Feedback
      <button onClick={handleModalClose} className='close-btn' aria-label='Close'>
        <FontAwesomeIcon icon={faTimes} aria-hidden='true' />
      </button>
    </Modal.Header>
    <Modal.Body className='presly-feedback-modal-body'>
      {feedbackSubmitted ?
        <>
          <div className='col-12 mt-4 submit-feedback-success-message text-center'>
            <FontAwesomeIcon icon={faCheck} className='mr-3' />
              Feedback Received
            </div>
          <div className='col-12 h5 mt-4 text-center submit-feedback-thank-you-message'>
            Thank you for helping to make Presly better!
            </div>
        </>
        : <>
          <div className='col-12 mb-3'>
            <h5 className='mb-0'>
              Let us know how we can make Presly better!
                <QuestionIcon content='Your feedback will be sent anonymously.' />
            </h5>
          </div>
          <div className='col-12 mb-0 feedback-form-container'>
            <textarea
              autoFocus={true}
              value={feedbackMessage}
              onChange={event => {
                trackTypedFeedbackThrottled();
                setFeedbackMessage(event.target.value);
              }}
              onClick={() => trackPreslyFeedbackModalEvent('Clicked Textarea')}
              disabled={sendingMessage}
              rows='4'
              className='form-control presly-input'
              id='feedbackMessageInput'
            />
          </div>
        </>
      }
    </Modal.Body>
    {!feedbackSubmitted &&
      <Modal.Footer className='presly-feedback-modal-footer'>
        <div className='col-12 text-right mx-0 mt-1 mb-3'>
          {!sendingMessage &&
            <button
              className='d-inline-block presly-btn-cancel'
              tabIndex='0'
              onClick={handleModalClose}
            >
              Cancel
            </button>
          }
          <button
            className='d-inline-block presly-btn send-presly-feedback-btn ml-4'
            onClick={() => {
              trackPreslyFeedbackModalEvent('Clicked Send');
              submitFeedback();
            }}
            disabled={sendingMessage}
            tabIndex='0'
          >
            {sendingMessage ?
              <div className='sending-message-container text-center'>
                <div className='spinner-border' role='status'></div>
                <span className='text'>Sending</span>
              </div>
              : <>
                Send Feedback
                </>
            }
          </button>
        </div>
      </Modal.Footer>
    }
  </Modal>;
}

export default PreslyFeedbackModal;
