import { sortBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  createTileOnServer,
  deleteTileOnServer,
  setTakeawayOnServer,
  createVisualOnServer,
  deleteVisualOnServer,
  setTileVisualTextOnServer,
  fetchTilesFromServer,
  createMultipleTilesOnServer,
  deleteAllTilesOnServer,
  setTilePositionsOnServer,
} from '../../../api/projects/StoryboardTilesAPI';

const storyboardTilesSlice = createSlice({
  name: 'storyboardTiles',
  initialState: {
    all: [],
    isFetching: true,
    editDictionary: {}
  },
  reducers: {
    // Tiles -- Create, Delete, Edit
    createTile(state, action) {
      state.createError = false;
      state.all.push({
        projectId: action.payload.projectId,
        id: action.payload.id,
        position: action.payload.position,
        takeaway: '',
        visuals: []
      })
    },
    createTileFailure(state) {
      state.createError = true;
    },
    deleteTile(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(tile => tile.id !== action.payload.id)
    },
    deleteTileFailure(state) {
      state.deleteError = true;
    },
    setTakeaway(state, action) {
      state.setTakeawayError = false;
      const tile = state.all.find(tile => tile.id === action.payload.id)
      if (tile) {
        tile.takeaway = action.payload.takeaway
      }
    },
    setTakeawayFailure(state) {
      state.setTakeawayError = true;
    },
    // Visuals -- Create, Delete, Edit
    createTileVisual(state, action) {
      state.createVisualError = false;
      const tile = state.all.find(tile => tile.id === action.payload.tileId)
      tile.visuals.push({
        id: action.payload.id,
        tileId: action.payload.tileId,
        position: action.payload.position,
        text: action.payload.text
      });
    },
    createTileVisualFailure(state) {
      state.createVisualError = true;
    },
    deleteTileVisual(state, action) {
      state.deleteVisualError = false;
      const tile = state.all.find(tile => tile.id === action.payload.tileId)
      tile.visuals = tile.visuals.filter(visual => visual.id !== action.payload.id)
    },
    deleteTileVisualFailure(state) {
      state.deleteVisualError = true;
    },
    setTileVisualText(state, action) {
      state.editVisualError = false;
      const tile = state.all.find(tile => tile.id === action.payload.tileId)
      const visual = tile.visuals.find(visual => visual.id === action.payload.id)
      visual.text = action.payload.text;
    },
    setTileVisualTextFailure(state) {
      state.editVisualError = true;
    },
    // Mass actions - Fetch, Push, Reorder, and setUserHasEditedTiles (I use the editDictionary to determine
    // whether or not to overwrite the current storyboard tiles with a new example storyboard when the user selects a new format)
    fetchTiles(state, action) {
      state.all = action.payload.tiles || [];
      state.isFetching = false;
      state.fetchError = false;
      if (action.payload.tiles?.length > 0) {
        state.editDictionary[action.payload.tiles[0].projectId] = true;
      }
    },
    fetchTilesFailure(state) {
      state.fetchError = true;
    },
    createMultipleTiles(state, action) {
      state.createMultipleTilesError = false;
      state.all.push(...action.payload.tiles);
    },
    createMultipleTilesFailure(state) {
      state.createMultipleTilesError = true;
    },
    deleteAllTiles(state, action) {
      state.deleteAllTilesError = false;
      state.all = [];
    },
    deleteAllTilesFailure(state) {
      state.deleteAllTilesError = true;
    },
    setTilePositions(state, action) {
      state.setTilePositionsError = false;

      // Updates the <position> value for tiles in the payload
      // state.all [{id: 0, position: Y},{id: 1, position: Y},{id: 2, position: Y},{id: 3, position: Y}]
      // action.payload.tiles: [{id: 1, position: Z},{id: 3, position: Z}]
      for (let i = 0; i < state.all.length; i++) {
        let id = state.all[i].id;
        let newTile = action.payload.tiles.find(tile => tile.id === id);
        if (!!newTile) {
          state.all[i].position = newTile.position;
        }
      }

      // Resort the tiles
      state.all = sortBy(state.all, 'position');
    },
    setTilePositionsFailure(state) {
      state.setTilePositionsError = true;
    },
    setUserHasEditedTiles(state, action) {
      state.editDictionary[action.payload.projectId] = action.payload.hasEdited;
    },
  }
})

export const createStoryboardTile = apiActionFactory(
  storyboardTilesSlice.actions.createTile,
  createTileOnServer,
  storyboardTilesSlice.actions.createTileFailure,
  true
);

export const deleteStoryboardTile = apiActionFactory(
  storyboardTilesSlice.actions.deleteTile,
  deleteTileOnServer,
  storyboardTilesSlice.actions.deleteTileFailure
);

export const setTileTakeaway = apiActionFactory(
  storyboardTilesSlice.actions.setTakeaway,
  setTakeawayOnServer,
  storyboardTilesSlice.actions.setTakeawayFailure
);

export const createTileVisual = apiActionFactory(
  storyboardTilesSlice.actions.createTileVisual,
  createVisualOnServer,
  storyboardTilesSlice.actions.createTileVisualFailure,
  true
);

export const deleteTileVisual = apiActionFactory(
  storyboardTilesSlice.actions.deleteTileVisual,
  deleteVisualOnServer,
  storyboardTilesSlice.actions.deleteTileVisualFailure
);

export const setTileVisualText = apiActionFactory(
  storyboardTilesSlice.actions.setTileVisualText,
  setTileVisualTextOnServer,
  storyboardTilesSlice.actions.setTileVisualTextFailure
);

export const fetchStoryboardTiles = apiActionFactory(
  storyboardTilesSlice.actions.fetchTiles,
  fetchTilesFromServer,
  storyboardTilesSlice.actions.fetchTilesFailure,
  true, true, false,
);

export const createMultipleTiles = apiActionFactory(
  storyboardTilesSlice.actions.createMultipleTiles,
  createMultipleTilesOnServer,
  storyboardTilesSlice.actions.createMultipleTilesFailure,
  true
);

export const deleteAllTiles = apiActionFactory(
  storyboardTilesSlice.actions.deleteAllTiles,
  deleteAllTilesOnServer,
  storyboardTilesSlice.actions.deleteAllTilesFailure,
);

export const setTilePositions = apiActionFactory(
  storyboardTilesSlice.actions.setTilePositions,
  setTilePositionsOnServer,
  storyboardTilesSlice.actions.setTilePositionsFailure
);

export const setUserHasEditedTiles = storyboardTilesSlice.actions.setUserHasEditedTiles;

export default storyboardTilesSlice.reducer
