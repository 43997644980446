import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// PrivateRoutes redirect to the sign in page if the user is not signed in.
function PrivateRoute({ component: Component, isAuthenticated, redirectTo, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated === true) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: redirectTo || '/signin',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string
}

export default PrivateRoute;
