import { getHumanitiesStoryboard } from './storyboards/Humanities';
import { getEngineeringStoryboard } from './storyboards/Engineering';
import { getScienceStoryboard } from './storyboards/Science';
import {
  EXAMPLE_PLAN_AUDIENCE_EXPECTATIONS_TEXT,
  EXAMPLE_PLAN_MESSAGE_TEXT,
  EXAMPLE_CREATE_FORMAT_VIDEO,
  EXAMPLE_DELIVER_TRAITS_VIDEO,
  EXAMPLE_DELIVER_METHOD_VIDEO
} from './SubjectManagerData';
import { DynamicImageImport, getModernImageSrc } from '../../components/common/ModernImage';
import { getModernVideoSrc } from '../../components/common/ModernVideo';

const getSubjectFolder = subject => {
  switch (subject) {
    case 'HUMANITIES':
      return 'humanities';
    case 'SCIENCE':
      return 'science';
    case 'ENGINEERING':
      return 'engineering';
    default:
      return 'humanities';
  }
}

class SubjectManager {
  NULL_SUBJECT = 'UNSPECIFIED';

  DEFAULT_SUBJECTS = {
    'HUMANITIES': 'Humanities',
    'SCIENCE': 'Science',
    'ENGINEERING': 'Engineering',
  };

  getExampleStoryboard(subject, format = 'classic', timeLowerBound = 10, timeUpperBound = 15) {
    if (!format) {
      format = 'classic';
    }
    let time = (timeLowerBound + timeUpperBound) / 2;
    let numberOfSlides = Math.round(time * 2 / 3);
    switch (subject) {
      case 'HUMANITIES':
        return getHumanitiesStoryboard(format, numberOfSlides);
      case 'SCIENCE':
        return getScienceStoryboard(format, numberOfSlides);
      case 'ENGINEERING':
        return getEngineeringStoryboard(format, numberOfSlides);
      default:
        return getHumanitiesStoryboard(format, numberOfSlides);
    }
  }

  // For images that we've optimized for serving over the Internet.
  // Example usage: BackgroundBad = SubjectManager.getDesignImageSet('SCIENCE', 'colors', 'BackgroundBad')
  getDesignImageSet(subject, imageFolder, imageName) {
    let subjectFolder = getSubjectFolder(subject);
    return getModernImageSrc(`./design/${imageFolder}/${subjectFolder}/${imageName}`);
  }

  // For videos that we've optimized for serving over the Internet.
  // Example usage: BackgroundBad = SubjectManager.getDesignImageSet('SCIENCE', 'images', 'AnimationsBad')
  getDesignVideoSet(subject, imageFolder, imageName) {
    let subjectFolder = getSubjectFolder(subject);
    return getModernVideoSrc(`./design/${imageFolder}/${subjectFolder}/${imageName}`);
  }

  // For all other images (e.g. gifs)
  // Example usage: AnimationsBad = SubjectManager.getDesignImage('SCIENCE', 'images', 'AnimationsBad.gif')
  getDesignImage(subject, imageFolder, imageNameWithExtension) {
    let subjectFolder = getSubjectFolder(subject);
    return DynamicImageImport(`./design/${imageFolder}/${subjectFolder}/${imageNameWithExtension}`);
  }

  getExamplePlanAudienceExpectationsText(subject) {
    if (!subject) {
      subject = SubjectManager.NULL_SUBJECT;
    }
    return EXAMPLE_PLAN_AUDIENCE_EXPECTATIONS_TEXT[subject];
  }

  getExamplePlanMessageText(subject) {
    if (!subject) {
      subject = SubjectManager.NULL_SUBJECT;
    }
    return EXAMPLE_PLAN_MESSAGE_TEXT[subject];
  }

  getExampleCreateFormatVideo(subject, format) {
    if (!subject) subject = SubjectManager.NULL_SUBJECT;
    if (!format) format = 'classic';
    if (EXAMPLE_CREATE_FORMAT_VIDEO[subject]) {
      return EXAMPLE_CREATE_FORMAT_VIDEO[subject][format];
    } else {
      console.log('ERROR: no such example format video found for subject <' + subject + '>');
      return { videoId: '' };
    }
  }

  getExampleDeliverTraitsVideo(subject) {
    if (!subject) {
      subject = SubjectManager.NULL_SUBJECT;
    }
    return EXAMPLE_DELIVER_TRAITS_VIDEO[subject];
  }

  getExampleDeliverMethodVideo(subject) {
    if (!subject) {
      subject = SubjectManager.NULL_SUBJECT;
    }
    return EXAMPLE_DELIVER_METHOD_VIDEO[subject];
  }
}

// This class is a singleton.
export default new SubjectManager();