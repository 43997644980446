import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ModernImage from './ModernImage';
import ModernVideo from './ModernVideo';
import './ClickableImage.scss';

const ClickableImage = ({ src, alt, uid, buttonClasses, classes, header, clickable = true, onClick, isVideo = false, ...props }) => {
  const [modalIsShowing, setModalIsShowing] = useState(false);

  const handleImageClick = event => {
    if (clickable) {
      setModalIsShowing(true);
    }
    if (onClick) {
      onClick(event);
    }
  }

  const handleModalClose = () => setModalIsShowing(false);

  return <>
    <button
      className={`clickable-image-wrapper text-center ${buttonClasses}`}
      tabIndex='0'
      onClick={handleImageClick}
    >
      {header && <div className='clickable-image-header'>{header}</div>}
      {isVideo ?
        <ModernVideo className={`img-fluid clickable-image shadow px-0 ${clickable && 'clickable'} ${classes}`} src={src} alt={alt} {...props} />
        : <ModernImage className={`img-fluid clickable-image shadow px-0 ${clickable && 'clickable'} ${classes}`} src={src} alt={alt} {...props} />
      }
    </button>

    <Modal
      id={uid}
      onHide={handleModalClose}
      show={modalIsShowing}
      centered
      size='lg'
    >
      <Modal.Header className='clickable-image-modal-header'>
        <button onClick={handleModalClose} className='close-btn' aria-label='Close'>
          <FontAwesomeIcon icon={faTimes} aria-hidden='true' />
        </button>
      </Modal.Header>
      <Modal.Body className='clickable-image-modal-body'>
        {isVideo ?
          <ModernVideo className='img-fluid rounded' src={src} alt={alt} {...props} />
          : <ModernImage className='img-fluid rounded' src={src} alt={alt} {...props} />
        }
      </Modal.Body>
    </Modal>
  </>
}

export default ClickableImage;
