import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import axios from 'axios';
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';

// Disable FontAwesome's auto-add-css feature, which goes
// against Presly's strict Content Security Policy.
// This code must come before any FontAwesome API calls.
fontAwesomeConfig.autoAddCss = false;

// If desired, uncomment this line during development to manually
// clear the Redux store when the page refreshes.
// persistor.purge();

// Configure axios to always use localhost:8000 as the
// baseURL during development (and not, for instance, localhost:3000)
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8000';
}
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

// Initialize Sentry
// TODO add better performance tracking sometime.
Sentry.init({
  dsn: "https://dbad7343829e48d3a45be8b0271177c4@o510762.ingest.sentry.io/5606890",
  autoSessionTracking: true,
  // TODO get the tracer working with google analytics, and confirm
  // that everything works with CORS.
  // Regarding GA, I may just need to add GA to the tracingOrigins list.
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     tracingOrigins: ['localhost', 'presly.org', 'www.presly.org']
  //   }),
  // ],

  // Track the performance of 1% of transactions.
  // tracesSampleRate: 0.01,
  // Either 'development' or 'production'
  environment: process.env.NODE_ENV,
});

// Render the App
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
