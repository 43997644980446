import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchAllPracticeSessionsFromServer = async function (payload) {
    try {
        let response = await axios.get('/api/practice-sessions');
        return { practiceSessions: response.data || [], currentUserId: payload.currentUserId };
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching practice sessions'));
    }
};

export const fetchPracticeSessionFromServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: fetchPracticeSessionFromServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.get('/api/practice-sessions/' + payload.id);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No practice session object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching practice sessions'));
    }
};

export const createPracticeSessionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('project') ||
        !payload.hasOwnProperty('duration') ||
        !payload.hasOwnProperty('pace') ||
        !payload.hasOwnProperty('silence') ||
        !payload.hasOwnProperty('smiles') ||
        !payload.hasOwnProperty('tonalVariation') ||
        !payload.hasOwnProperty('transcript')) {
        throw new Error('Missing Parameter: createPracticeSessionOnServer requires that the payload contain <project>, <duration>, <pace>, <silence>, <smiles>, <tonalVariation>, and <transcript>');
    }

    // Replace <project> with <projectId> for the post request.
    payload.projectId = payload.project;
    delete payload.project;

    try {
        let response = await axios.post('/api/practice-sessions', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No practice session object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/practice-sessions failed'));
    }
};

export const deletePracticeSessionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deletePracticeSessionOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/practice-sessions/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/practice-sessions/' + payload.id + ' failed'));
    }
};

// Updates the practiceSession with all of the fields included in payload
// Valid fields are {name, academicTerm}
export const updatePracticeSessionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: updatePracticeSessionOnServer requires that the payload contain the practiceSession <id>');
    }

    // Replace <project> with <projectId> for the update if necessary.
    if (payload.project) {
        payload.projectId = payload.project;
        delete payload.project;
    }

    try {
        let response = await axios.patch('/api/practice-sessions/' + payload.id, payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PATCH /api/practice-sessions/' + payload.id + ' failed'));
    }
}
