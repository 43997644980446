import React from 'react';
import PreslyTippy from './PreslyTippy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconWithTooltip.scss';

const IconWithTooltip = ({ icon, content, className = '', ...props }) => {
  return <PreslyTippy
    content={content}
  >
    <span
      className={'ml-2 cursor-pointer ' + className}
      aria-label={content}
      tabIndex='0'
      {...props}
    >
      <FontAwesomeIcon icon={icon} />
    </span>
  </PreslyTippy>
}

export default IconWithTooltip;
