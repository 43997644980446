import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SubjectManager from '../../assets/constants/SubjectManager';
import { getReactSelectTheme } from '../../assets/constants/constants';
import './SubjectInput.scss';

// TODO if I'm looking for something to refactor I should
// re-build this component using the general SelectInput component.

const mapSubjectsToSelectOptions = subjects => {
  let result = [];
  for (const [subject, name] of Object.entries(subjects)) {
    result.push({ value: subject, label: name });
  }
  return result;
}

const subjects = mapSubjectsToSelectOptions(SubjectManager.DEFAULT_SUBJECTS)

const mapValueToInternalValue = value => {
  if (value && SubjectManager.DEFAULT_SUBJECTS.hasOwnProperty(value)) {
    return { value, label: SubjectManager.DEFAULT_SUBJECTS[value] }
  } else {
    // Only a value of `null` re-renders the Select component -- for some
    // reason, setting the value to `undefined` doesn't trigger a re-render.
    return null;
  }
}

export default ({ value, onChange, disabled, className, ...props }) => {
  const [internalValue, setInternalValue] = useState(mapValueToInternalValue(value))

  useEffect(() => {
    setInternalValue(mapValueToInternalValue(value));
  }, [value]);

  const handleOnChange = obj => {
    if (onChange) {
      onChange(obj?.value);
    }
  }
  return (
    <Select
      isClearable={true}
      isSearchable={true}
      onChange={handleOnChange}
      value={internalValue}
      options={subjects}
      name='subjectSelect'
      className={`subject-select presly-input ${className || ''}`}
      classNamePrefix='select'
      theme={getReactSelectTheme}
      isDisabled={disabled}
      {...props}
    />
  )
}