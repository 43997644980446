import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
}

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['persist/PERSIST'],
    }
  })
});

export const persistor = persistStore(store);

// The state object is structured like so: 
// {
//     projects: {
//         projects: {
//             isFetching: false,
//             all: [
//                 { id: 1, title: 'Biology 101', ... },
//                 { id: 2, title: 'Spanish 101', ... }
//             ],
//             activeProjectId: 1
//         },
//         audienceFieldChecklists: {
//             all: []
//         }, 
//         etc...
//     }
// }