import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchProjectsFromServer,
  fetchProjectFromServer,
  createProjectOnServer,
  updateProjectOnServer,
  deleteProjectOnServer,
  setTimeLowerBoundOnServer,
  setTimeUpperBoundOnServer,
  setMessageOnServer,
  setAudienceExpectationsOnServer,
  setFormatOnServer
} from '../../../api/projects/ProjectsAPI';
import { map } from 'lodash';
import { ANONYMOUS_USER_PROJECT_ID } from '../../../assets/constants/constants';

export const DEFAULT_PROJECT_FOR_ANONYMOUS_USERS = {
  id: ANONYMOUS_USER_PROJECT_ID,
  title: 'My Project',
  timeLowerBound: 10,
  timeUpperBound: 15,
  pageLastEdited: 'PLAN',
  subject: 'HUMANITIES',
  completionPercentage: -1,
  activity: 0,
  user: {},
};

const doCreateProject = (state, action) => {
  state.createError = false;
  if (action.payload.lastActivity) {
    action.payload.lastActivity = (new Date(action.payload.lastActivity)).valueOf();
  }
  state.all.push(action.payload);
}

const doUpdateProject = (state, action) => {
  state.updateError = false;
  const project = state.all.find(project => project.id === action.payload.id)
  for (const [key, value] of Object.entries(action.payload)) {
    if (key === 'lastActivity') {
      project[key] = (new Date(value)).valueOf();
    } else {
      project[key] = value;
    }
  }
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    createProject(state, action) {
      doCreateProject(state, action);
    },
    createProjectFailure(state) {
      state.createError = true;
    },
    updateProject(state, action) {
      doUpdateProject(state, action);
    },
    updateProjectFailure(state) {
      state.updateError = true;
    },
    deleteProject(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(project => project.id !== action.payload.id)
    },
    deleteProjectFailure(state) {
      state.deleteError = true;
    },
    populateProjects(state, action) {
      // Don't overwrite projects not owned by the currentUser
      // (i.e. student projects which the currentUser has permission to view)
      const projectsNotOwnedByCurrentUser = state.all.filter(project => project.user.id !== action.payload.currentUserId);
      // Store dateCreated values as milliseconds.
      const projectsWithNumericalDates = map(action.payload.projects, project => {
        if (project.lastActivity) {
          project.lastActivity = (new Date(project.lastActivity)).valueOf();
        }
        return project;
      });
      state.all = projectsWithNumericalDates.concat(projectsNotOwnedByCurrentUser);
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchProjectsFailure(state) {
      state.fetchError = true;
    },
    fetchSingleProject(state, action) {
      state.fetchSingleProjectError = false;
      let project = state.all.find(project => project.id === action.payload.id)
      // Update or create, as necessary.
      if (project) {
        doUpdateProject(state, action);
      } else {
        doCreateProject(state, action);
      }
    },
    fetchSingleProjectFailure(state) {
      state.fetchSingleProjectError = true;
    },
    setActiveProject(state, action) {
      state.activeProjectId = action.payload.id;
    },
    setTimeLowerBound(state, action) {
      state.timeError = false;
      doUpdateProject(state, action);
    },
    setTimeUpperBound(state, action) {
      state.timeError = false;
      doUpdateProject(state, action);
    },
    setTimeFailure(state) {
      state.timeError = true;
    },
    setAudienceExpectations(state, action) {
      state.audienceExpectationsError = false;
      doUpdateProject(state, action);
    },
    setAudienceExpectationsFailure(state) {
      state.audienceExpectationsError = true;
    },
    setMessage(state, action) {
      state.messageError = false;
      doUpdateProject(state, action);
    },
    setMessageFailure(state) {
      state.messageError = true;
    },
    setFormat(state, action) {
      state.formatError = false;
      doUpdateProject(state, action);
    },
    setFormatFailure(state) {
      state.formatError = true;
    },
  }
})


export const createProject = apiActionFactory(
  projectsSlice.actions.createProject,
  createProjectOnServer,
  projectsSlice.actions.createProjectFailure,
  true
);

export const updateProject = apiActionFactory(
  projectsSlice.actions.updateProject,
  updateProjectOnServer,
  projectsSlice.actions.updateProjectFailure
);

export const updateProjectActivity = apiActionFactory(
  projectsSlice.actions.updateProject,
  updateProjectOnServer,
  projectsSlice.actions.updateProjectFailure,
  false,
  false
);

export const deleteProject = apiActionFactory(
  projectsSlice.actions.deleteProject,
  deleteProjectOnServer,
  projectsSlice.actions.deleteProjectFailure
);

export const fetchProjects = apiActionFactory(
  projectsSlice.actions.populateProjects,
  fetchProjectsFromServer,
  projectsSlice.actions.fetchProjectsFailure,
  true
);

export const fetchProject = apiActionFactory(
  projectsSlice.actions.fetchSingleProject,
  fetchProjectFromServer,
  projectsSlice.actions.fetchSingleProjectFailure,
  true
);

export const setTimeLowerBound = apiActionFactory(
  projectsSlice.actions.setTimeLowerBound,
  setTimeLowerBoundOnServer,
  projectsSlice.actions.setTimeFailure
);

export const setTimeUpperBound = apiActionFactory(
  projectsSlice.actions.setTimeUpperBound,
  setTimeUpperBoundOnServer,
  projectsSlice.actions.setTimeFailure
);

export const setMessage = apiActionFactory(
  projectsSlice.actions.setMessage,
  setMessageOnServer,
  projectsSlice.actions.setMessageFailure
);

export const setAudienceExpectations = apiActionFactory(
  projectsSlice.actions.setAudienceExpectations,
  setAudienceExpectationsOnServer,
  projectsSlice.actions.setAudienceExpectationsFailure
);

export const setFormat = apiActionFactory(
  projectsSlice.actions.setFormat,
  setFormatOnServer,
  projectsSlice.actions.setFormatFailure
);

export const { setActiveProject } = projectsSlice.actions

export default projectsSlice.reducer
