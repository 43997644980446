import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../../redux/features/courses/coursesSelector';
import { clearReduxStore } from '../../../redux/rootReducer';
import { trackNavbarEvent } from '../../../assets/utils/GoogleAnalytics';
import { executeOnEnter } from '../../../assets/utils/utils';
import './UserDropdown.scss';


const UserDropdown = ({ user, onSelect, handlePreslyFeedbackModalOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const courses = useSelector(getCourses);

  function handleWindowMouseClick(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      onSelect();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleWindowMouseClick);
    return () => {
      document.removeEventListener('click', handleWindowMouseClick);
    }
  });

  const trackProjectsLinkClick = () => {
    trackNavbarEvent('Clicked Projects');
    onSelect();
  }

  const trackCoursesLinkClick = () => {
    trackNavbarEvent('Clicked Courses');
    onSelect();
  }

  const trackAssignmentsLinkClick = () => {
    trackNavbarEvent('Clicked Assignments');
    onSelect();
  }

  const trackMessagesLinkClick = () => {
    trackNavbarEvent('Clicked Messages');
    onSelect();
  }

  const trackAccountLinkClick = () => {
    trackNavbarEvent('Clicked Account');
    onSelect();
  }

  const onSendFeedbackClick = () => {
    trackNavbarEvent('Clicked Send Presly Feedback');
    handlePreslyFeedbackModalOpen();
    onSelect();
  }

  const logout = () => {
    trackNavbarEvent('Clicked Log Out');
    let response = dispatch(clearReduxStore());
    if (response) {
      window.location = '/api/users/logout';
    }
  }

  return (
    <div className='user-dropdown-wrapper'>
      <div className='container-fluid'>
        <div className='row justify-content-end'>
          <div ref={dropdownRef} className='user-dropdown col-12'>
            <div className='row'>
              <div className='col-12'>
                <div className={'row ' + (location.pathname.includes('/user') ? 'd-sm-none' : '')}>
                  <div className={'col-12' + (user?.instructor || !user?.student?.isIndependentUser ? ' order-1' : ' order-2')}>
                    <Link className='clear-link-styling user-dropdown-btn user-dropdown-open-courses-page-btn no-select' to='/user/courses/' onClick={trackCoursesLinkClick}>
                      Courses
                    </Link>
                  </div>
                  <div className={'col-12' + (user?.instructor || !user?.student?.isIndependentUser ? ' order-2' : ' order-1')}>
                    <Link className='clear-link-styling user-dropdown-btn user-dropdown-open-projects-page-btn no-select' to='/user/projects' onClick={trackProjectsLinkClick}>
                      Projects
                    </Link>
                  </div>
                  {!user?.instructor && courses?.length > 0 &&
                    <div className='col-12 order-3'>
                      <Link className='clear-link-styling user-dropdown-btn user-dropdown-open-assignments-page-btn no-select' to='/user/assignments' onClick={trackAssignmentsLinkClick} >
                        Assignments
                      </Link>
                    </div>
                  }
                  {courses?.length > 0 &&
                    <div className='col-12 order-4'>
                      <Link className='clear-link-styling user-dropdown-btn user-dropdown-open-messages-page-btn no-select' to='/user/messages' onClick={trackMessagesLinkClick}>
                        Messages
                      </Link>
                    </div>
                  }
                </div>
                {/* <div className={'row ' + (location.pathname.includes('/user') ? 'd-sm-none' : '')}>
                  <div className='col-12'>
                    <div className='divider'></div>
                  </div>
                </div> */}

                <div className='row'>
                  <div className='col-12'>
                    <Link className='clear-link-styling user-dropdown-btn user-dropdown-open-account-btn no-select' to='/user/account' onClick={trackAccountLinkClick}>
                      Account
                    </Link>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <button
                      className='user-dropdown-btn user-dropdown-send-feedback-btn no-select'
                      onClick={onSendFeedbackClick}
                    >
                      Send Feedback
                    </button>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className='divider'></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div
                      className='user-dropdown-btn user-dropdown-logout-btn no-select'
                      onClick={logout}
                      onKeyPress={event => executeOnEnter(event, logout)}
                      tabIndex='0'
                    >
                      Log Out
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDropdown;
