import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchAllAssignmentsFromServer = async function () {
    try {
        let response = await axios.get('/api/assignments');
        return response.data || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching assignments'));
    }
};

export const createAssignmentOnServer = async function (payload) {
    try {
        let response = await axios.post('/api/assignments', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/assignments failed'));
    }
};

export const updateAssignmentOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: updateAssignmentOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.patch('/api/assignments/' + payload.id, payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PATCH /api/assignments/' + payload.id + ' failed'));
    }
};

export const deleteAssignmentOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteAssignmentOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/assignments/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/assignments/' + payload.id + ' failed'));
    }
};
