import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchAllPracticeSessionsFromServer,
  createPracticeSessionOnServer,
  updatePracticeSessionOnServer,
  deletePracticeSessionOnServer,
  fetchPracticeSessionFromServer,
} from '../../../api/practiceSessions/PracticeSessionsAPI';
import { map } from 'lodash';

const doCreatePracticeSession = (state, action) => {
  state.createError = false;
  if (action.payload.dateCreated) {
    action.payload.dateCreated = (new Date(action.payload.dateCreated)).valueOf();
  }
  state.all.push(action.payload);
}

const doUpdatePracticeSession = (state, action) => {
  state.updateError = false;
  const practiceSession = state.all.find(practiceSession => practiceSession.id === action.payload.id)
  for (const [key, value] of Object.entries(action.payload)) {
    if (key === 'dateCreated') {
      practiceSession[key] = (new Date(value)).valueOf();
    } else {
      practiceSession[key] = value;
    }
  }
}

const practiceSessionsSlice = createSlice({
  name: 'practiceSessions',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    createPracticeSession(state, action) {
      doCreatePracticeSession(state, action);
    },
    createPracticeSessionFailure(state) {
      state.createError = true;
    },
    updatePracticeSession(state, action) {
      doUpdatePracticeSession(state, action);
    },
    updatePracticeSessionFailure(state) {
      state.updateError = true;
    },
    fetchPracticeSession(state, action) {
      state.fetchError = false;
      const practiceSession = state.all.find(practiceSession => practiceSession.id === action.payload.id)
      // Update or create, as necessary.
      if (practiceSession) {
        doUpdatePracticeSession(state, action);
      } else {
        doCreatePracticeSession(state, action);
      }
    },
    fetchPracticeSessionFailure(state) {
      state.fetchError = true;
    },
    deletePracticeSession(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(practiceSession => practiceSession.id !== action.payload.id)
    },
    deletePracticeSessionFailure(state) {
      state.deleteError = true;
    },
    populatePracticeSessions(state, action) {
      // Don't overwrite practiceSessions not owned by the currentUser
      // (i.e. student practiceSessions which the currentUser has permission to view)
      const practiceSessionsNotOwnedByCurrentUser = state.all.filter(practiceSession => practiceSession.project.user?.id !== action.payload.currentUserId);
      // Store dateCreated values as milliseconds.
      let practiceSessionsWithNumericalDates = map(action.payload.practiceSessions, practiceSession => {
        if (practiceSession.dateCreated) {
          practiceSession.dateCreated = (new Date(practiceSession.dateCreated)).valueOf();
        }
        return practiceSession;
      });
      state.all = practiceSessionsWithNumericalDates.concat(practiceSessionsNotOwnedByCurrentUser);
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchAllPracticeSessionsFailure(state) {
      state.fetchError = true;
    },
    setActivePracticeSession(state, action) {
      state.activePracticeSessionId = action.payload.id
    },
  }
})


export const createPracticeSession = apiActionFactory(
  practiceSessionsSlice.actions.createPracticeSession,
  createPracticeSessionOnServer,
  practiceSessionsSlice.actions.createPracticeSessionFailure,
  true
);

export const updatePracticeSession = apiActionFactory(
  practiceSessionsSlice.actions.updatePracticeSession,
  updatePracticeSessionOnServer,
  practiceSessionsSlice.actions.updatePracticeSessionFailure,
);

export const deletePracticeSession = apiActionFactory(
  practiceSessionsSlice.actions.deletePracticeSession,
  deletePracticeSessionOnServer,
  practiceSessionsSlice.actions.deletePracticeSessionFailure
);

export const fetchPracticeSessions = apiActionFactory(
  practiceSessionsSlice.actions.populatePracticeSessions,
  fetchAllPracticeSessionsFromServer,
  practiceSessionsSlice.actions.fetchAllPracticeSessionsFailure,
  true
);

export const fetchPracticeSession = apiActionFactory(
  practiceSessionsSlice.actions.fetchPracticeSession,
  fetchPracticeSessionFromServer,
  practiceSessionsSlice.actions.fetchPracticeSessionFailure,
  true
);

export const { setActivePracticeSession } = practiceSessionsSlice.actions

export default practiceSessionsSlice.reducer;
