import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const createPreslyFeedbackMessageOnServer = async function (payload) {
    if (!payload.hasOwnProperty('text')) {
        throw new Error('Missing Parameter: createPreslyFeedbackMessageOnServer requires that the payload contain <text>');
    }

    try {
        let response = await axios.post('/api/presly-feedback-messages', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No feedback message object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/feedback-message failed'));
    }
};

export const fetchPreslyFeedbackMessagesFromServer = async function () {
    try {
        const response = await axios.get('/api/presly-feedback-messages');
        return response.data || [];
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching Presly feedback messages'));
    }
};

export const markPreslyFeedbackMessageAsReadOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: markPreslyFeedbackMessageAsReadOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.post(`/api/presly-feedback-messages/${payload.id}/mark_as_read`);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No feedback message object returned');
        }
    } catch (error) {
        throw new Error(`Error: POST /api/presly-feedback-messages/${payload.id}/mark_as_read failed`);
    }
};
