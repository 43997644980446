import React from 'react';
import styles from './ErrorBoundaryFallback.module.scss';

const ErrorBoundaryFallback = (props) => {
  const { className, message } = props || {};
  // Render Presly's custom error fallback UI here.
  return () => <h3 className={`text-center ${styles.container} ${className}`}>
    {message || 'Something went wrong. Please refresh the page.'}
  </h3>;
}

export default ErrorBoundaryFallback;
