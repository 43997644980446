import React, { useEffect, useRef } from 'react';
import { truncate } from 'lodash';
import { useLocation } from 'react-router';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { trackNavbarEvent } from '../../../assets/utils/GoogleAnalytics';
import { getIndexOfCurrentCourseDashboardPageName } from '../../../assets/utils/utils';
import './NavbarSelectors.scss';


const getTruncatedCourseName = (courseName, windowWidth) => {
  if (!courseName) return '';
  return truncate(courseName, { length: windowWidth / 12 });
}

const CourseSelector = ({ courses, activeCourse, onSelect }) => {
  const location = useLocation();
  const selectorRef = useRef(null);
  const windowWidth = useWindowWidth();

  function handleWindowMouseClick(event) {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      onSelect();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleWindowMouseClick);
    return () => {
      document.removeEventListener('click', handleWindowMouseClick);
    }
  });

  const getCourseURL = course => {
    // TODO consider replacing with getCourseDashboardPageUrl
    let pageName = location.pathname.slice(getIndexOfCurrentCourseDashboardPageName(location.pathname));
    return '/user/course/' + course.id + pageName;
  }

  const trackCourseSelectionEvent = course => {
    if (course.id === activeCourse?.id) {
      trackNavbarEvent('Selected Current Course');
    } else {
      trackNavbarEvent('Selected Other Course');
    }
    onSelect();
  }

  const openCoursesPage = () => {
    trackNavbarEvent('Clicked Add Course');
    onSelect();
  }

  const getSelectCourseBtn = course => {
    return <Link
      className={'clear-link-styling select-course-btn no-select' + (course.id === activeCourse?.id ? ' active' : '')}
      onClick={() => trackCourseSelectionEvent(course)}
      to={getCourseURL(course)}
    >
      <span className='float-left mr-5'>
        {getTruncatedCourseName(course.name, windowWidth)}
      </span>
      {course.id === activeCourse?.id &&
        <span className='float-right ml-4'>
          <FontAwesomeIcon icon={faCheck} />
        </span>
      }
    </Link>
  }

  return (
    <div ref={selectorRef} className='course-selector container-fluid'>
      {courses.map(course =>
        <div key={`course-${course.id}`} className='row'>
          <div className='col-12'>
            {getSelectCourseBtn(course)}
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-12'>
          <div className='divider'></div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Link
            className='clear-link-styling add-course-btn-nav no-select'
            onClick={openCoursesPage}
            to='/user/courses/active'
          >
            <FontAwesomeIcon icon={faPlus} className='mr-3' />
            Add Course
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CourseSelector;
