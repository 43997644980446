import { padStart, isString, isUndefined } from 'lodash';
import { DateTime } from 'luxon';

// Make sure these values line up with those found in api/models.py.
export const isFreeUser = user => user.licenseTier === 'FREE';
export const isDemoUser = user => user.licenseTier === 'DEMO';
export const isTrialUser = user => user.licenseTier === 'TRIAL';
export const isProUser = user => user.licenseTier === 'PRO';

export const getPageLastEditedURL = pageLastEdited => {
  switch (pageLastEdited) {
    case 'PLAN':
      return '/plan';
    case 'CREATE':
      return '/create';
    case 'DESIGN':
      return '/design';
    case 'DELIVER':
      return '/deliver';
    default:
      return '/plan';
  }
}

export const getUserSlideshowUploadSizeLimit = user => {
  return 1000000;  // 1 MB
  // if (isFreeUser(user)) return 0;
  // if (isDemoUser(user)) return 15000000;
  // if (isTrialUser(user)) return 50000000;
  // if (isProUser(user)) return 50000000;
  // return 0;
}

export const getUserAttachmentUploadSizeLimit = user => {
  return 1000000;  // 1 MB
  // if (isFreeUser(user)) return 0;
  // if (isDemoUser(user)) return 2500000;
  // if (isTrialUser(user)) return 30000000;
  // if (isProUser(user)) return 30000000;
  // return 0;
}

export const getUserAttachmentUploadCountLimit = user => {
  if (isFreeUser(user)) return 0;
  if (isDemoUser(user)) return 10;
  if (isTrialUser(user)) return 25;
  if (isProUser(user)) return 200;
  return 0;
}


export const executeOnEnter = (event, callback) => {
  if (event.which === 13 || event.keyCode === 13 || event.key === 'Enter') {
    callback(event);
  }
}


const getDateTimeFromMillisOrISOString = date => {
  if (isString(date)) {
    return DateTime.fromISO(date);
  } else {
    return DateTime.fromMillis(date);
  }
}

export const printTimeZonePretty = date => {
  if (!date) {
    // If no date is provided, then print the user's time zone.
    return DateTime.local().offsetNameLong;
  }
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.offsetNameLong;
}

export const printTimeZonePrettyCompact = date => {
  if (!date) {
    // If no date is provided, then print the user's time zone.
    return DateTime.local().offsetNameShort;
  }
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.offsetNameShort;
}

export const printDatePretty = date => {
  if (!date) return '';
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.toLocaleString({ month: 'long', day: 'numeric' });
}

export const printDateTimePretty = date => {
  if (!date) return '';
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.toLocaleString({ month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' });
}

export const printTimePretty = date => {
  if (!date) return '';
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.toLocaleString({ hour: 'numeric', minute: '2-digit' });
}

export const printDateWithWeekdayPretty = date => {
  if (!date) return '';
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' });
}

export const printDateTimeWithWeekdayPretty = date => {
  if (!date) return '';
  const dt = getDateTimeFromMillisOrISOString(date);
  return dt.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' });
}


export const printDurationPretty = (durationInMilliseconds, showDecimals = false, padMinutes = false) => {
  if (isUndefined(durationInMilliseconds)) return '';
  let hours = Math.floor(durationInMilliseconds / 3600000);
  durationInMilliseconds %= 3600000;
  let minutes = Math.floor(durationInMilliseconds / 60000);
  durationInMilliseconds %= 60000;
  let seconds = Math.floor(durationInMilliseconds / 1000);
  let decimalString = 's';
  if (showDecimals) {
    decimalString = '.' + padStart(Math.round((durationInMilliseconds % 1000) / 10), 2, '0') + 's';
  }
  if (hours) {
    minutes = padStart(minutes + 'm', padMinutes ? 3 : 2, '0');
    seconds = padStart(seconds, 2, '0');
    return hours + 'h ' + minutes + ' ' + seconds + decimalString;
  } else {
    minutes = padStart(minutes + 'm', padMinutes ? 3 : 2, '0');
    seconds = padStart(seconds, 2, '0');
    return minutes + ' ' + seconds + decimalString;
  }
}

export const getIndexOfCurrentCourseDashboardPageName = pathname => {
  let currentPageNameIndex = pathname.indexOf('/students');
  if (currentPageNameIndex === -1) {
    currentPageNameIndex = pathname.indexOf('/assignments');
  }
  if (currentPageNameIndex === -1) {
    currentPageNameIndex = pathname.indexOf('/customize-content');
  }
  if (currentPageNameIndex === -1) {
    currentPageNameIndex = pathname.indexOf('/settings');
  }
  return currentPageNameIndex;
}

export const getCourseDashboardPageURL = (
  pathname, options = {
    newPageName: '',
    keepSection: false,
    keepAssignment: false,
    customTail: '',
    newCourseId: '',
    newSectionId: '',
    newAssignmentId: '',
  }) => {
  if (!pathname) {
    throw Error('getCourseDashboardPageURL requires one parameter: <pathname>')
  }
  let currentPageNameIndex = getIndexOfCurrentCourseDashboardPageName(pathname);
  if (!options.newPageName) {
    // Set the new newPageName if it's not provided.
    // Note that I assume here that the pageName is the last part of the URL.
    options.newPageName = pathname.slice(currentPageNameIndex);
  }

  let prefix = pathname.slice(0, currentPageNameIndex);
  if (!options.keepSection || options.newSectionId) {
    // Remove the section filter from the prefix if requested.
    let sectionTextIndex = prefix.indexOf('/section');
    if (sectionTextIndex !== -1) {
      let slashAfterSectionId = prefix.indexOf('/', sectionTextIndex + 9);
      if (slashAfterSectionId !== -1) {
        // Either replace or remove the activeSectionId, as requested.
        if (options.newSectionId) {
          // Replace
          prefix = prefix.slice(0, sectionTextIndex + 9) + options.newSectionId + prefix.slice(slashAfterSectionId)
        } else {
          // Remove
          prefix = prefix.slice(0, sectionTextIndex) + prefix.slice(slashAfterSectionId)
        }
      } else {
        prefix = prefix.slice(0, sectionTextIndex);
      }
    }
  }

  if (!options.keepAssignment || options.newAssignmentId) {
    // Remove the assignment filter from the prefix if requested.
    let assignmentTextIndex = prefix.indexOf('/assignment');
    if (assignmentTextIndex !== -1) {
      prefix = prefix.slice(0, assignmentTextIndex);
    }
  }
  if (options.newAssignmentId) {
    // Add `/assignment/:activeAssignmentId` to the URL if requested.
    prefix += `/assignment/${options.newAssignmentId}`;
  }

  return prefix + options.newPageName + (options.customTail || '');
}


// Returns the index after the current page name for any of the /user/* pages.
export const getTerminationIndexOfCurrentUserPageName = pathname => {
  let currentPageNameIndex = pathname.indexOf('/user/courses');
  if (currentPageNameIndex !== -1) {
    return currentPageNameIndex + 13;
  }
  currentPageNameIndex = pathname.indexOf('/user/projects');
  if (currentPageNameIndex !== -1) {
    return currentPageNameIndex + 14;
  }
  currentPageNameIndex = pathname.indexOf('/user/assignments');
  if (currentPageNameIndex !== -1) {
    return currentPageNameIndex + 17;
  }
  currentPageNameIndex = pathname.indexOf('/user/account');
  if (currentPageNameIndex !== -1) {
    return currentPageNameIndex + 13;
  }
  return currentPageNameIndex;
}

export const getUserPageURL = (
  pathname, options = {
    newPageName: '',
    keepCourse: false,
    keepAssignment: false,
    keepProject: false,
    customTail: '',
    newCourseId: '',
    newAssignmentId: '',
  }) => {
  if (!pathname) {
    throw Error('getUserPageURL requires one parameter: <pathname>')
  }
  let currentPageNameTerminationIndex = getTerminationIndexOfCurrentUserPageName(pathname);
  if (!options.newPageName) {
    // Set the new newPageName if it's not provided.
    // Note that I assume here that the pageName is the last part of the URL.
    options.newPageName = pathname.slice(5, currentPageNameTerminationIndex);
  }

  let suffix = pathname.slice(currentPageNameTerminationIndex);

  // Project ID
  if (!options.keepProject || options.newProjectId) {
    // Remove the project filter from the suffix if requested.
    let projectTextIndex = suffix.indexOf('/project/');
    let suffixTail = '';
    if (projectTextIndex !== -1) {
      let slashAfterProjectId = suffix.indexOf('/', projectTextIndex + 8);
      if (slashAfterProjectId !== -1) {
        suffixTail = suffix.slice(slashAfterProjectId);
      }

      // Remove the current project ID from the suffix.
      suffix = suffix.slice(0, projectTextIndex);
    }
    if (options.newProjectId) {
      // If the project filter is not currently present, add it.
      suffix = '/project/' + options.newProjectId + suffix + suffixTail;
    }
  }

  // Assigment ID
  if (!options.keepAssignment || options.newAssignmentId) {
    // Remove the assignment filter from the suffix if requested.
    let assignmentTextIndex = suffix.indexOf('/assignment/');
    let suffixTail = '';
    if (assignmentTextIndex !== -1) {
      let slashAfterAssignmentId = suffix.indexOf('/', assignmentTextIndex + 8);
      if (slashAfterAssignmentId !== -1) {
        suffixTail = suffix.slice(slashAfterAssignmentId);
      }

      // Remove the current assignment ID
      suffix = suffix.slice(0, assignmentTextIndex);
    }
    if (options.newAssignmentId) {
      // If the assignment filter is not currently present, add it.
      suffix = '/assignment/' + options.newAssignmentId + suffix + suffixTail;
    }
  }

  // Course ID
  if (!options.keepCourse || options.newCourseId) {
    // Remove the course filter from the suffix if requested.
    let courseTextIndex = suffix.indexOf('/course/');
    let suffixTail = '';
    if (courseTextIndex !== -1) {
      let slashAfterCourseId = suffix.indexOf('/', courseTextIndex + 8);
      if (slashAfterCourseId !== -1) {
        suffixTail = suffix.slice(slashAfterCourseId);
      }

      // Remove the current course ID
      suffix = suffix.slice(0, courseTextIndex);
    }
    if (options.newCourseId) {
      // If the course filter is not currently present, add it.
      suffix = '/course/' + options.newCourseId + suffix + suffixTail;
    }
  }

  return '/user' + options.newPageName + suffix + (options.customTail || '');
}
