// The sections of the full science storyboard are:
// (3 slides) intro -- title, overview of presentation, outline of presentation
// (3 slides) overview -- marine mammals, baleen vs. toothed, geography.
// (3 slides) traits -- size, temperature regulation, breathing.
// (10 slides) types of whales -- humpback whales, blue whales, gray whales, fin whales, killer whales, sperm whales, right whales, common dolphins, bottlenose dolphins, river dolphins
// (3 slides) interactions with humans -- International Whaling Commission, current endangered status, future outlook
// (2 slides) conclusion -- summary, thank you + questions
const scienceClassic = [
  { id: 0, position: 0, priority: 1, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Whales"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, priority: 9, takeaway: 'Get the audience interested in whales', visuals: [{ id: 0, position: 0, text: 'Picture of a dolphin (dolphins are whales!)' }] },
  { id: 2, position: 2, priority: 12, takeaway: 'Outline the sections of my presentation', visuals: [{ id: 0, position: 0, text: 'List of numbered text boxes (one for each section)' }] },
  { id: 3, position: 3, priority: 2, takeaway: 'Overview: Whales are Marine Mammals', visuals: [{ id: 0, position: 0, text: 'List of the key traits of marine mammals that whales have' }] },
  { id: 4, position: 4, priority: 3, takeaway: 'Overview: Toothed Whales vs. Baleen Whales', visuals: [{ id: 0, position: 0, text: 'Picture of whale teeth' }, { id: 1, position: 1, text: 'Picture of baleen plates' }] },
  { id: 5, position: 5, priority: 4, takeaway: 'Overview: Geographical Distribution', visuals: [{ id: 0, position: 0, text: 'Color-coded map of the world\'s oceans and seas' }] },
  { id: 6, position: 6, priority: 5, takeaway: 'Traits: Size', visuals: [{ id: 0, position: 0, text: 'Chart comparing whale sizes' }] },
  { id: 7, position: 7, priority: 7, takeaway: 'Traits: Temperature Regulation', visuals: [{ id: 0, position: 0, text: 'Pictures of a small and a large whale' }, { id: 1, position: 1, text: 'List of the key facts regarding whales\' temperature regulation' }] },
  { id: 8, position: 8, priority: 8, takeaway: 'Traits: Breathing', visuals: [{ id: 0, position: 0, text: 'Picture of a whale coming up for air' }, { id: 1, position: 1, text: 'Diagram of a whale\'s respiratory system' }] },
  { id: 9, position: 9, priority: 15, takeaway: 'Species: Humpback Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Humpback Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 10, position: 10, priority: 16, takeaway: 'Species: Blue Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Blue Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 11, position: 11, priority: 17, takeaway: 'Species: Gray Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Gray Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 12, position: 12, priority: 18, takeaway: 'Species: Fin Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Fin Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 13, position: 13, priority: 19, takeaway: 'Species: Orca Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Orca Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 14, position: 14, priority: 20, takeaway: 'Species: Sperm Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Sperm Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 15, position: 15, priority: 21, takeaway: 'Species: Right Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Right Whale' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 16, position: 16, priority: 22, takeaway: 'Species: Common Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a Common Dolphin' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 17, position: 17, priority: 23, takeaway: 'Species: Bottlenose Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a Bottlenose Dolphin' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 18, position: 18, priority: 24, takeaway: 'Species: River Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a River Dolphin' }, { id: 1, position: 1, text: 'List of key facts' }] },
  { id: 19, position: 19, priority: 10, takeaway: 'History: International Whaling Commission', visuals: [{ id: 0, position: 0, text: 'Chart of the whale population over time' }, { id: 1, position: 1, text: 'List of the commission\'s key activities' }] },
  { id: 20, position: 20, priority: 11, takeaway: 'History: Current Endangered Status', visuals: [{ id: 0, position: 0, text: 'List of the most endangered whales (including their statuses)' }] },
  { id: 21, position: 21, priority: 13, takeaway: 'History: Future Outlook', visuals: [{ id: 0, position: 0, text: 'Picture of a happy whale' }] },
  { id: 22, position: 22, priority: 14, takeaway: 'Recap & Summary', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 23, position: 23, priority: 6, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];
const scienceTED = [
  { id: 0, position: 0, priority: 1, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Whales"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, priority: 9, takeaway: 'Get the audience interested in whales', visuals: [{ id: 0, position: 0, text: 'Picture of a dolphin (dolphins are whales!)' }] },
  { id: 2, position: 2, priority: 12, takeaway: 'Outline the sections of my presentation', visuals: [{ id: 0, position: 0, text: 'List of numbered text boxes (one for each section)' }] },
  { id: 3, position: 3, priority: 2, takeaway: 'Overview: Whales are Marine Mammals', visuals: [{ id: 0, position: 0, text: 'List of the key traits of marine mammals that whales have' }] },
  { id: 4, position: 4, priority: 3, takeaway: 'Overview: Toothed Whales vs. Baleen Whales', visuals: [{ id: 0, position: 0, text: 'Picture of whale teeth' }, { id: 1, position: 1, text: 'Picture of baleen plates' }] },
  { id: 5, position: 5, priority: 4, takeaway: 'Overview: Geographical Distribution', visuals: [{ id: 0, position: 0, text: 'Color-coded map of the world\'s oceans and seas' }] },
  { id: 6, position: 6, priority: 5, takeaway: 'Traits: Size', visuals: [{ id: 0, position: 0, text: 'Chart comparing whale sizes' }] },
  { id: 7, position: 7, priority: 7, takeaway: 'Traits: Temperature Regulation', visuals: [{ id: 0, position: 0, text: 'Pictures of a small and a large whale' }] },
  { id: 8, position: 8, priority: 8, takeaway: 'Traits: Breathing', visuals: [{ id: 0, position: 0, text: 'Picture of a whale coming up for air' }] },
  { id: 9, position: 9, priority: 15, takeaway: 'Species: Humpback Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Humpback Whale' }] },
  { id: 10, position: 10, priority: 16, takeaway: 'Species: Blue Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Blue Whale' }] },
  { id: 11, position: 11, priority: 17, takeaway: 'Species: Gray Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Gray Whale' }] },
  { id: 12, position: 12, priority: 18, takeaway: 'Species: Fin Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Fin Whale' }] },
  { id: 13, position: 13, priority: 19, takeaway: 'Species: Orca Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Orca Whale' }] },
  { id: 14, position: 14, priority: 20, takeaway: 'Species: Sperm Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Sperm Whale' }] },
  { id: 15, position: 15, priority: 21, takeaway: 'Species: Right Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Right Whale' }] },
  { id: 16, position: 16, priority: 22, takeaway: 'Species: Common Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a Common Dolphin' }] },
  { id: 17, position: 17, priority: 23, takeaway: 'Species: Bottlenose Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a Bottlenose Dolphin' }] },
  { id: 18, position: 18, priority: 24, takeaway: 'Species: River Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a River Dolphin' }] },
  { id: 19, position: 19, priority: 10, takeaway: 'History: International Whaling Commission', visuals: [{ id: 0, position: 0, text: 'Chart of the whale population over time' }] },
  { id: 20, position: 20, priority: 11, takeaway: 'History: Current Endangered Status', visuals: [{ id: 0, position: 0, text: 'List of the most endangered whales (including their statuses)' }] },
  { id: 21, position: 21, priority: 13, takeaway: 'History: Future Outlook', visuals: [{ id: 0, position: 0, text: 'Picture of a happy whale' }] },
  { id: 22, position: 22, priority: 14, takeaway: 'Recap & Summary', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 23, position: 23, priority: 6, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];
const scienceLightningTalk = scienceTED;
const sciencePechaKucha = [
  { id: 0, position: 0, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Whales"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, takeaway: 'Get the audience interested in whales', visuals: [{ id: 0, position: 0, text: 'Picture of a dolphin (dolphins are whales!)' }] },
  { id: 2, position: 2, takeaway: 'Overview: Whales are Marine Mammals', visuals: [{ id: 0, position: 0, text: 'List of the key traits of marine mammals that whales have' }] },
  { id: 3, position: 3, takeaway: 'Overview: Toothed Whales vs. Baleen Whales', visuals: [{ id: 0, position: 0, text: 'Picture of baleen plates' }] },
  { id: 4, position: 4, takeaway: 'Overview: Geographical Distribution', visuals: [{ id: 0, position: 0, text: 'Color-coded map of the world\'s oceans and seas' }] },
  { id: 5, position: 5, takeaway: 'Traits: Size', visuals: [{ id: 0, position: 0, text: 'Chart comparing whale sizes' }] },
  { id: 6, position: 6, takeaway: 'Traits: Temperature Regulation', visuals: [{ id: 0, position: 0, text: 'Pictures of a small and a large whale' }] },
  { id: 7, position: 7, takeaway: 'Traits: Breathing', visuals: [{ id: 0, position: 0, text: 'Picture of a whale coming up for air' }] },
  { id: 8, position: 8, takeaway: 'Species: Humpback Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Humpback Whale' }] },
  { id: 9, position: 9, takeaway: 'Species: Blue Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Blue Whale' }] },
  { id: 10, position: 10, takeaway: 'Species: Orca Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Orca Whale' }] },
  { id: 11, position: 11, takeaway: 'Species: Sperm Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Sperm Whale' }] },
  { id: 12, position: 12, takeaway: 'Species: Right Whales', visuals: [{ id: 0, position: 0, text: 'Picture of a Right Whale' }] },
  { id: 13, position: 13, takeaway: 'Species: Bottlenose Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a Bottlenose Dolphin' }] },
  { id: 14, position: 14, takeaway: 'Species: River Dolphins', visuals: [{ id: 0, position: 0, text: 'Picture of a River Dolphin' }] },
  { id: 15, position: 15, takeaway: 'History: International Whaling Commission', visuals: [{ id: 0, position: 0, text: 'Chart of the whale population over time' }] },
  { id: 16, position: 16, takeaway: 'History: Current Endangered Status', visuals: [{ id: 0, position: 0, text: 'List of the most endangered whales (including their statuses)' }] },
  { id: 17, position: 17, takeaway: 'History: Future Outlook', visuals: [{ id: 0, position: 0, text: 'Picture of a happy whale' }] },
  { id: 18, position: 18, takeaway: 'Recap & Summary', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 19, position: 19, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];

// This function uses the 'priority' property of each takeaway
// to filter the given storyboard down to the given 'numberOfSlides'.
const getAbridgedStoryboard = (fullStoryboard, numberOfSlides) => {
  return fullStoryboard.filter(el => el.priority <= numberOfSlides);
}

const getScienceStoryboardClassic = numberOfSlides => {
  return getAbridgedStoryboard(scienceClassic, numberOfSlides);
}

const getScienceStoryboardTED = numberOfSlides => {
  return getAbridgedStoryboard(scienceTED, numberOfSlides);
}

const getScienceStoryboardLightning = numberOfSlides => {
  return getAbridgedStoryboard(scienceLightningTalk, numberOfSlides);
}

const getScienceStoryboardPechaKucha = () => {
  // All Pecha Kucha presentations have 20 slides,
  // so we'll never need to abridge the full storyboard.
  return sciencePechaKucha;
}

export const getScienceStoryboard = (format, numberOfSlides) => {
  switch (format) {
    case 'classic':
      return getScienceStoryboardClassic(numberOfSlides);
    case 'ted':
      return getScienceStoryboardTED(numberOfSlides);
    case 'lightning':
      return getScienceStoryboardLightning(numberOfSlides);
    case 'pechakucha':
      return getScienceStoryboardPechaKucha(numberOfSlides);
    default:
      throw Error('Improper usage of getScienceStoryboard: <format> not recognized');
  }
}