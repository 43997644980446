import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchAllAssignmentsFromServer,
  createAssignmentOnServer,
  updateAssignmentOnServer,
  deleteAssignmentOnServer
} from '../../../api/assignments/AssignmentsAPI';
import { map } from 'lodash';

const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    populateAssignments(state, action) {
      // Store dueDates as milliseconds.
      let assignmentsWithNumericalDates = map(action.payload, assignment => {
        if (assignment.dueDate) {
          assignment.dueDate = (new Date(assignment.dueDate)).valueOf();
        }
        if (assignment.dateCreated) {
          assignment.dateCreated = (new Date(assignment.dateCreated)).valueOf();
        }
        return assignment;
      });
      let annotatedAssignments = map(assignmentsWithNumericalDates, assignment => {
        assignment.nameLowerCase = assignment.name.toLowerCase();
        assignment.descriptionLowerCase = assignment.description.toLowerCase();
        return assignment;
      });
      state.all = annotatedAssignments;
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchAssignmentsFailure(state) {
      state.isFetching = false;
      state.fetchError = true;
    },
    createAssignment(state, action) {
      state.createError = false;
      if (action.payload.dueDate) {
        action.payload.dueDate = (new Date(action.payload.dueDate)).valueOf();
      }
      if (action.payload.dateCreated) {
        action.payload.dateCreated = (new Date(action.payload.dateCreated)).valueOf();
      }
      state.all.push(action.payload);
    },
    createAssignmentFailure(state) {
      state.createError = true;
    },
    updateAssignment(state, action) {
      state.updateError = false;
      let assignment = state.all.find(assignment => assignment.id === action.payload.id);
      for (const [key, value] of Object.entries(action.payload)) {
        if (key === 'dateCreated' || key === 'dueDate') {
          assignment[key] = (new Date(value)).valueOf();
        } else {
          assignment[key] = value;
        }
      }
    },
    updateAssignmentFailure(state) {
      state.updateError = true;
    },
    deleteAssignment(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(assignment => assignment.id !== action.payload.id)
    },
    deleteAssignmentFailure(state) {
      state.deleteError = true;
    },
  }
});

export const fetchAllAssignments = apiActionFactory(
  assignmentsSlice.actions.populateAssignments,
  fetchAllAssignmentsFromServer,
  assignmentsSlice.actions.fetchAssignmentsFailure,
  true
);

export const createAssignment = apiActionFactory(
  assignmentsSlice.actions.createAssignment,
  createAssignmentOnServer,
  assignmentsSlice.actions.createAssignmentFailure,
  true
);

export const updateAssignment = apiActionFactory(
  assignmentsSlice.actions.updateAssignment,
  updateAssignmentOnServer,
  assignmentsSlice.actions.updateAssignmentFailure,
  true
);

export const deleteAssignment = apiActionFactory(
  assignmentsSlice.actions.deleteAssignment,
  deleteAssignmentOnServer,
  assignmentsSlice.actions.deleteAssignmentFailure
);

export default assignmentsSlice.reducer;
