import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchCustomPedagogyConfigurationsFromServer,
  createCustomPedagogyConfigurationOnServer,
  updateCustomPedagogyConfigurationOnServer,
  deleteCustomPedagogyConfigurationOnServer,
} from '../../../api/customPedagogyConfigurations/CustomPedagogyConfigurationsAPI';

const customPedagogyConfigurationsSlice = createSlice({
  name: 'customPedagogyConfigurations',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    createCustomPedagogyConfiguration(state, action) {
      state.createError = false;
      state.all.push(action.payload);
    },
    createCustomPedagogyConfigurationFailure(state) {
      state.createError = true;
    },
    updateCustomPedagogyConfiguration(state, action) {
      state.updateError = false;
      const customPedagogyConfiguration = state.all.find(customPedagogyConfiguration => customPedagogyConfiguration.id === action.payload.id)
      for (const [key, value] of Object.entries(action.payload)) {
        customPedagogyConfiguration[key] = value;
      }
    },
    updateCustomPedagogyConfigurationFailure(state) {
      state.updateError = true;
    },
    deleteCustomPedagogyConfiguration(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(customPedagogyConfiguration => customPedagogyConfiguration.id !== action.payload.id)
    },
    deleteCustomPedagogyConfigurationFailure(state) {
      state.deleteError = true;
    },
    populateCustomPedagogyConfigurations(state, action) {
      state.all = action.payload.customPedagogyConfigurations
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchCustomPedagogyConfigurationsFailure(state) {
      state.fetchError = true;
    },
  }
})


export const createCustomPedagogyConfiguration = apiActionFactory(
  customPedagogyConfigurationsSlice.actions.createCustomPedagogyConfiguration,
  createCustomPedagogyConfigurationOnServer,
  customPedagogyConfigurationsSlice.actions.createCustomPedagogyConfigurationFailure,
  true
);

export const updateCustomPedagogyConfiguration = apiActionFactory(
  customPedagogyConfigurationsSlice.actions.updateCustomPedagogyConfiguration,
  updateCustomPedagogyConfigurationOnServer,
  customPedagogyConfigurationsSlice.actions.updateCustomPedagogyConfigurationFailure,
);

export const deleteCustomPedagogyConfiguration = apiActionFactory(
  customPedagogyConfigurationsSlice.actions.deleteCustomPedagogyConfiguration,
  deleteCustomPedagogyConfigurationOnServer,
  customPedagogyConfigurationsSlice.actions.deleteCustomPedagogyConfigurationFailure
);

export const fetchCustomPedagogyConfigurations = apiActionFactory(
  customPedagogyConfigurationsSlice.actions.populateCustomPedagogyConfigurations,
  fetchCustomPedagogyConfigurationsFromServer,
  customPedagogyConfigurationsSlice.actions.fetchCustomPedagogyConfigurationsFailure,
  true
);

// This function is a util function that will create or update configuration objects as necessary.
export const dispatchPedagogyConfigUpdate = async (activeConfigId, activeAssignmentId, activeCourseId, updateObject, dispatch) => {
  if (activeConfigId) {
    updateObject.id = activeConfigId;
    return dispatch(updateCustomPedagogyConfiguration(updateObject));
  } else if (activeAssignmentId) {
    updateObject.assignment = activeAssignmentId;
    return await dispatch(createCustomPedagogyConfiguration(updateObject));
  } else if (activeCourseId) {
    updateObject.course = activeCourseId;
    return await dispatch(createCustomPedagogyConfiguration(updateObject));
  } else {
    throw Error('<dispatchPedagogyConfigUpdate> requires that either <activeConfigId>, <activeAssignmentId>, or <activeCourseId> be defined.')
  }
}

export default customPedagogyConfigurationsSlice.reducer
