import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchCoursesFromServer = async function () {
    try {
        let response = await axios.get('/api/courses');
        return { courses: response.data || [] };
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching courses'));
    }
};

export const createCourseOnServer = async function (payload) {
    if (!payload.hasOwnProperty('name') || !payload.hasOwnProperty('academicTerm')) {
        throw new Error('Missing Parameter: createCourseOnServer requires that the payload contain <name> and <academicTerm>');
    }

    try {
        let response = await axios.post('/api/courses', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No course object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/courses failed'));
    }
};

export const duplicateCourseOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: duplicateCourseOnServer requires that the payload contain the <id> of the course to duplicate');
    }

    try {
        let response = await axios.post('/api/courses/' + payload.id + '/duplicate', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No course object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/courses/' + payload.id + '/duplicate failed'));
    }
};

export const deleteCourseOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteCourseOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/courses/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/courses/' + payload.id + ' failed'));
    }
};

// Updates the course with all of the fields included in payload
// Valid fields are {name, academicTerm}
export const updateCourseOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: updateCourseOnServer requires that the payload contain the course <id>');
    }

    try {
        let response = await axios.patch('/api/courses/' + payload.id, payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PATCH /api/courses/' + payload.id + ' failed'));
    }
}

export const removeCurrentUserFromCourseOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: removeCurrentUserFromCourseOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/courses/' + payload.id + '/unregister');
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/courses/' + payload.id + '/unregister failed'));
    }
};

export const registerForCourseSectionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('registrationCode')) {
        throw new Error('Missing Parameter: registerForCourseSectionOnServer requires that the payload contain <registrationCode>');
    }

    try {
        let response = await axios.post('/api/courses/register', { registrationCode: payload.registrationCode });
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: No course object returned');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/courses/register failed'));
    }
};

export const createCourseSectionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('course') || !payload.hasOwnProperty('name')) {
        throw new Error('Missing Parameter: createCourseSectionOnServer requires that the payload contain <course> (i.e. courseId) and <name>');
    }

    try {
        let response = await axios.post('/api/course-sections', payload);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: POST /api/course-sections failed'));
    }
}

export const renameCourseSectionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id') || !payload.hasOwnProperty('name')) {
        throw new Error('Missing Parameter: renameCourseSectionOnServer requires that the payload contain <id> and <name>');
    }

    try {
        let response = await axios.patch('/api/course-sections/' + payload.id, { name: payload.name });
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PATCH /api/course-sections/' + payload.id + ' failed'));
    }
}

export const deleteCourseSectionOnServer = async function (payload) {
    if (!payload.hasOwnProperty('id')) {
        throw new Error('Missing Parameter: deleteCourseSectionOnServer requires that the payload contain <id>');
    }

    try {
        let response = await axios.delete('/api/course-sections/' + payload.id);
        if (response.status === 204) {
            return payload;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/course-sections/' + payload.id + ' failed'));
    }
}
