import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  createFeedbackMessageOnServer,
  fetchAllFeedbackFromServer,
  fetchProjectFeedbackFromServer,
  fetchSlideshowFeedbackFromServer,
  fetchPracticeSessionFeedbackFromServer,
  markFeedbackMessageAsReadOnServer
} from '../../../api/feedbackMessages/FeedbackMessagesAPI';
import { map } from 'lodash';

const doFetchFeedback = (state, action, modelName) => {
  if (action.payload?.length > 0) {
    const id = action.payload[0][modelName].id;

    // First, remove any existing feedback messages for this project / slideshow / practice session
    state.all = state.all.filter(message => !message[modelName]?.id || message[modelName]?.id !== id);

    // Now, add all of the returned messages to the state.
    let messagesWithNumericalDates = map(action.payload, message => {
      if (message.dateCreated) {
        message.dateCreated = (new Date(message.dateCreated)).valueOf();
      }
      return message;
    });
    state.all = state.all.concat(messagesWithNumericalDates);
  }
}

const feedbackMessagesSlice = createSlice({
  name: 'feedbackMessages',
  initialState: {
    all: []
  },
  reducers: {
    createProjectFeedbackMessage(state, action) {
      state.createProjectFeedbackError = false;
      state.all.push(action.payload);
    },
    createProjectFeedbackMessageFailure(state) {
      state.createProjectFeedbackError = true;
    },
    createSlideshowFeedbackMessage(state, action) {
      state.createSlideshowFeedbackError = false;
      state.all.push(action.payload);
    },
    createSlideshowFeedbackMessageFailure(state) {
      state.createSlideshowFeedbackError = true;
    },
    createPracticeSessionFeedbackMessage(state, action) {
      state.createPracticeSessionFeedbackError = false;
      state.all.push(action.payload);
    },
    createPracticeSessionFeedbackMessageFailure(state) {
      state.createPracticeSessionFeedbackError = true;
    },
    fetchAllFeedback(state, action) {
      state.fetchAllFeedbackError = false;
      let messagesWithNumericalDates = map(action.payload, message => {
        if (message.dateCreated) {
          message.dateCreated = (new Date(message.dateCreated)).valueOf();
        }
        return message;
      });
      state.all = messagesWithNumericalDates;
    },
    fetchAllFeedbackFailure(state) {
      state.fetchAllFeedbackError = true;
    },
    fetchProjectFeedback(state, action) {
      state.fetchProjectFeedbackError = false;
      doFetchFeedback(state, action, 'project');
    },
    fetchProjectFeedbackFailure(state) {
      state.fetchProjectFeedbackError = true;
    },
    fetchSlideshowFeedback(state, action) {
      state.fetchSlideshowFeedbackError = false;
      doFetchFeedback(state, action, 'slideshow')
    },
    fetchSlideshowFeedbackFailure(state) {
      state.fetchSlideshowFeedbackError = true;
    },
    fetchPracticeSessionFeedback(state, action) {
      state.fetchPracticeSessionFeedbackError = false;
      doFetchFeedback(state, action, 'practiceSession');
    },
    fetchPracticeSessionFeedbackFailure(state) {
      state.fetchPracticeSessionFeedbackError = true;
    },
    markFeedbackMessageAsRead(state, action) {
      state.markFeedbackMessageAsReadError = false;
      const feedbackMessage = state.all.find(feedbackMessage => feedbackMessage.id === action.payload.id)
      if (feedbackMessage) {
        feedbackMessage.read = true;
      }
    },
    markFeedbackMessageAsReadFailure(state) {
      state.markFeedbackMessageAsReadError = true;
    },
    setActiveFeedbackProjectId(state, action) {
      state.activeFeedbackProjectId = action.payload.id
    },
    setActiveFeedbackSlideshowId(state, action) {
      state.activeFeedbackSlideshowId = action.payload.id
    },
    setActiveFeedbackPracticeSessionId(state, action) {
      state.activeFeedbackPracticeSessionId = action.payload.id
    },
    // editFeedbackMessage(state, action) {
    //   state.editError = false;
    //   const feedbackMessage = state.all.find(feedbackMessage => feedbackMessage.id === action.payload.id)
    //   if (feedbackMessage) {
    //     feedbackMessage.text = action.payload.text
    //   }
    // },
    // editFeedbackMessageFailure(state) {
    //   state.editError = true;
    // },
    // deleteFeedbackMessage(state, action) {
    //   state.deleteError = false;
    //   state.all = state.all.filter(feedbackMessage => feedbackMessage.id !== action.payload.id)
    // },
    // deleteFeedbackMessageFailure(state) {
    //   state.deleteError = true;
    // },
  }
})


export const createProjectFeedbackMessage = apiActionFactory(
  feedbackMessagesSlice.actions.createProjectFeedbackMessage,
  createFeedbackMessageOnServer,
  feedbackMessagesSlice.actions.createProjectFeedbackMessageFailure,
  true
);

export const createSlideshowFeedbackMessage = apiActionFactory(
  feedbackMessagesSlice.actions.createSlideshowFeedbackMessage,
  createFeedbackMessageOnServer,
  feedbackMessagesSlice.actions.createSlideshowFeedbackMessageFailure,
  true
);

export const createPracticeSessionFeedbackMessage = apiActionFactory(
  feedbackMessagesSlice.actions.createPracticeSessionFeedbackMessage,
  createFeedbackMessageOnServer,
  feedbackMessagesSlice.actions.createPracticeSessionFeedbackMessageFailure,
  true
);

export const fetchAllFeedback = apiActionFactory(
  feedbackMessagesSlice.actions.fetchAllFeedback,
  fetchAllFeedbackFromServer,
  feedbackMessagesSlice.actions.fetchAllFeedbackFailure,
  true
);

export const fetchProjectFeedback = apiActionFactory(
  feedbackMessagesSlice.actions.fetchProjectFeedback,
  fetchProjectFeedbackFromServer,
  feedbackMessagesSlice.actions.fetchProjectFeedbackFailure,
  true
);

export const fetchSlideshowFeedback = apiActionFactory(
  feedbackMessagesSlice.actions.fetchSlideshowFeedback,
  fetchSlideshowFeedbackFromServer,
  feedbackMessagesSlice.actions.fetchSlideshowFeedbackFailure,
  true
);

export const fetchPracticeSessionFeedback = apiActionFactory(
  feedbackMessagesSlice.actions.fetchPracticeSessionFeedback,
  fetchPracticeSessionFeedbackFromServer,
  feedbackMessagesSlice.actions.fetchPracticeSessionFeedbackFailure,
  true
);

export const markFeedbackMessageAsRead = apiActionFactory(
  feedbackMessagesSlice.actions.markFeedbackMessageAsRead,
  markFeedbackMessageAsReadOnServer,
  feedbackMessagesSlice.actions.markFeedbackMessageAsReadFailure
);

// export const editFeedbackMessage = apiActionFactory(
//   feedbackMessagesSlice.actions.editFeedbackMessage,
//   editFeedbackMessageOnServer,
//   feedbackMessagesSlice.actions.editFeedbackMessageFailure
// );

// export const deleteFeedbackMessage = apiActionFactory(
//   feedbackMessagesSlice.actions.deleteFeedbackMessage,
//   deleteFeedbackMessageOnServer,
//   feedbackMessagesSlice.actions.deleteFeedbackMessageFailure
// );

export const { setActiveFeedbackProjectId, setActiveFeedbackSlideshowId, setActiveFeedbackPracticeSessionId } = feedbackMessagesSlice.actions

export default feedbackMessagesSlice.reducer
