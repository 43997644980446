import React, { useEffect, useRef } from 'react';
import { trackNavbarEvent } from '../../../assets/utils/GoogleAnalytics';
import { updateProject } from '../../../redux/features/projects/projectsSlice';
import { useDispatch } from 'react-redux';
import SubjectInput from '../../common/SubjectInput';
import QuestionIcon from '../../common/QuestionIcon';
import './NavbarSelectors.scss';


const SubjectSelector = ({ activeProject, onSelect }) => {
  const dispatch = useDispatch();
  const selectorRef = useRef(null);

  function handleWindowMouseClick(event) {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      onSelect();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleWindowMouseClick);
    return () => {
      document.removeEventListener('click', handleWindowMouseClick);
    }
  });

  const selectSubject = subject => {
    if (!subject) {
      return;
    }

    if (subject === activeProject?.subject) {
      trackNavbarEvent('Selected Current Subject');
    } else {
      trackNavbarEvent('Selected Other Subject');
    }

    dispatch(updateProject({ id: activeProject?.id, subject }));
    onSelect();
  }

  return (
    <div ref={selectorRef} className='subject-selector container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <div className='select-subject-input'>
            <label htmlFor='anonymous-project-subject-input' className='ml-2'>
              Subject
              <QuestionIcon content="Presly will customize what it teaches based on the subject. The example storyboard, for instance, will be different for science projects versus humanities projects." />
            </label>
            <SubjectInput
              id='anonymous-project-subject-input'
              value={activeProject?.subject}
              onChange={selectSubject}
              isClearable={false}
              // defaultMenuIsOpen={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubjectSelector;
