// The sections of the full engineering storyboard are:
// (3 slides) intro -- title, overview of presentation, outline of presentation
// (3 slides) key metrics for sorting algorithms -- time efficiency, space efficiency, stability
// (3 slides) simple sorting algorithms -- insertion sort, selection sort, counting sort
// (3 slides) efficient sorting algorithms -- merge sort, quicksort, heapsort
// (6 slides) example implementations -- insertion sort, selection sort, counting sort, merge sort, quicksort, heapsort
// (4 slides) case studies -- quicksort, insertion sort, counting sort
// (2 slides) conclusion -- summary, thank you + questions
const engineeringClassic = [
  { id: 0, position: 0, priority: 1, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Sorting Algorithms"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, priority: 9, takeaway: 'Get the audience interested in sorting algorithms', visuals: [{ id: 0, position: 0, text: 'Pictures of three businesses that are made possible by efficient sorting algorithms (Google, Airlines, and Uber)' }] },
  { id: 2, position: 2, priority: 12, takeaway: 'Outline the sections of my presentation', visuals: [{ id: 0, position: 0, text: 'List of numbered text boxes (one for each section)' }] },
  { id: 3, position: 3, priority: 2, takeaway: 'Key Metric: Time Efficiency', visuals: [{ id: 0, position: 0, text: 'Animation showing a fast algorithm and a slow algorithm working side-by-side' }, { id: 1, position: 1, text: 'Table showing common sorting algorithm efficiencies' }] },
  { id: 4, position: 4, priority: 7, takeaway: 'Key Metric: Space Efficiency', visuals: [{ id: 0, position: 0, text: 'Bar graph showing the relative spatial efficiencies of popular sorting algorithms' }] },
  { id: 5, position: 5, priority: 8, takeaway: 'Key Metric: Stability', visuals: [{ id: 0, position: 0, text: 'Picture illustrating stability' }, { id: 1, position: 1, text: 'Definition of stability' }] },
  { id: 6, position: 6, priority: 3, takeaway: 'Algorithm: Insertion Sort', visuals: [{ id: 0, position: 0, text: 'High-level explanation of how insertion sort works' }, { id: 1, position: 1, text: 'Animation of insertion sort' }, { id: 2, position: 2, text: 'Efficiency & stability values' }] },
  { id: 7, position: 7, priority: 15, takeaway: 'Algorithm: Selection Sort', visuals: [{ id: 0, position: 0, text: 'High-level explanation of how selection sort works' }, { id: 1, position: 1, text: 'Animation of selection sort' }, { id: 2, position: 2, text: 'Efficiency & stability values' }] },
  { id: 8, position: 8, priority: 16, takeaway: 'Algorithm: Counting Sort', visuals: [{ id: 0, position: 0, text: 'High-level explanation of how counting sort works' }, { id: 1, position: 1, text: 'Animation of counting sort' }, { id: 2, position: 2, text: 'Efficiency & stability values' }] },
  { id: 9, position: 9, priority: 4, takeaway: 'Algorithm: Merge Sort', visuals: [{ id: 0, position: 0, text: 'High-level explanation of how merge sort works' }, { id: 1, position: 1, text: 'Animation of merge sort' }, { id: 2, position: 2, text: 'Efficiency & stability values' }] },
  { id: 10, position: 10, priority: 5, takeaway: 'Algorithm: Quicksort', visuals: [{ id: 0, position: 0, text: 'High-level explanation of how quicksort works' }, { id: 1, position: 1, text: 'Animation of quicksort' }, { id: 2, position: 2, text: 'Efficiency & stability values' }] },
  { id: 11, position: 11, priority: 23, takeaway: 'Algorithm: Heapsort', visuals: [{ id: 0, position: 0, text: 'High-level explanation of how heapsort works' }, { id: 1, position: 1, text: 'Animation of heapsort' }, { id: 2, position: 2, text: 'Efficiency & stability values' }] },
  { id: 12, position: 12, priority: 10, takeaway: 'Example Implementation: Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for insertion sort' }] },
  { id: 13, position: 13, priority: 22, takeaway: 'Example Implementation: Selection Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for selection sort' }] },
  { id: 14, position: 14, priority: 19, takeaway: 'Example Implementation: Counting Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for counting sort' }] },
  { id: 15, position: 15, priority: 11, takeaway: 'Example Implementation: Merge Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for merge sort' }] },
  { id: 16, position: 16, priority: 14, takeaway: 'Example Implementation: Quicksort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for quicksort' }] },
  { id: 17, position: 17, priority: 24, takeaway: 'Example Implementation: Heapsort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for heapsort' }] },
  { id: 18, position: 18, priority: 17, takeaway: 'Case Study: Sorting NBA Players by Lifetime Point Totals using Quicksort', visuals: [{ id: 0, position: 0, text: 'Table of all-time points leaders' }, { id: 1, position: 1, text: 'List of reasons to use quicksort in this situation' }] },
  { id: 19, position: 19, priority: 18, takeaway: 'Case Study: Sorting Olympic Athletes by Gold, Silver, and Bronze Medals Won using Merge Sort', visuals: [{ id: 0, position: 0, text: 'Table of all-time medal leaders' }, { id: 1, position: 1, text: 'List of reasons to use merge sort in this situation (stability)' }] },
  { id: 20, position: 20, priority: 20, takeaway: 'Case Study: Sorting 20 Test Grades using Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Picture of a gradebook' }, { id: 1, position: 1, text: 'List of reasons to use insertion sort in this situation' }] },
  { id: 21, position: 21, priority: 21, takeaway: 'Case Study: Sorting 1 Million Standardized Test Results using Counting Sort', visuals: [{ id: 0, position: 0, text: 'Picture of a standardized test' }, { id: 1, position: 1, text: 'List of reasons to use counting sort in this situation' }] },
  { id: 22, position: 22, priority: 13, takeaway: 'Recap & Summary of Sorting Algorithms', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 23, position: 23, priority: 6, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];
const engineeringTED = [
  { id: 0, position: 0, priority: 1, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Sorting Algorithms"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, priority: 9, takeaway: 'Get the audience interested in sorting algorithms', visuals: [{ id: 0, position: 0, text: 'Pictures of three businesses that are made possible by efficient sorting algorithms (Google, Airlines, and Uber)' }] },
  { id: 2, position: 2, priority: 12, takeaway: 'Outline the sections of my presentation', visuals: [{ id: 0, position: 0, text: 'None' }] },
  { id: 3, position: 3, priority: 2, takeaway: 'Key Metric: Time Efficiency', visuals: [{ id: 0, position: 0, text: 'Animation showing a fast algorithm and a slow algorithm working side-by-side' }] },
  { id: 4, position: 4, priority: 7, takeaway: 'Key Metric: Space Efficiency', visuals: [{ id: 0, position: 0, text: 'Bar graph showing the relative spatial efficiencies of popular sorting algorithms' }] },
  { id: 5, position: 5, priority: 8, takeaway: 'Key Metric: Stability', visuals: [{ id: 0, position: 0, text: 'Definition of stability' }] },
  { id: 6, position: 6, priority: 3, takeaway: 'Algorithm: Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 7, position: 7, priority: 15, takeaway: 'Algorithm: Selection Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 8, position: 8, priority: 16, takeaway: 'Algorithm: Counting Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 9, position: 9, priority: 4, takeaway: 'Algorithm: Merge Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 10, position: 10, priority: 5, takeaway: 'Algorithm: Quicksort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 11, position: 11, priority: 23, takeaway: 'Algorithm: Heapsort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 12, position: 12, priority: 10, takeaway: 'Example Implementation: Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for insertion sort' }] },
  { id: 13, position: 13, priority: 22, takeaway: 'Example Implementation: Selection Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for selection sort' }] },
  { id: 14, position: 14, priority: 19, takeaway: 'Example Implementation: Counting Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for counting sort' }] },
  { id: 15, position: 15, priority: 11, takeaway: 'Example Implementation: Merge Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for merge sort' }] },
  { id: 16, position: 16, priority: 14, takeaway: 'Example Implementation: Quicksort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for quicksort' }] },
  { id: 17, position: 17, priority: 24, takeaway: 'Example Implementation: Heapsort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for heapsort' }] },
  { id: 18, position: 18, priority: 17, takeaway: 'Case Study: Sorting NBA Players by Lifetime Point Totals using Quicksort', visuals: [{ id: 0, position: 0, text: 'Table of all-time points leaders' }] },
  { id: 19, position: 19, priority: 18, takeaway: 'Case Study: Sorting Olympic Athletes by Gold, Silver, and Bronze Medals Won using Merge Sort', visuals: [{ id: 0, position: 0, text: 'Table of all-time medal leaders' }] },
  { id: 20, position: 20, priority: 20, takeaway: 'Case Study: Sorting 20 Test Grades using Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Picture of a gradebook' }] },
  { id: 21, position: 21, priority: 21, takeaway: 'Case Study: Sorting 1 Million Standardized Test Results using Counting Sort', visuals: [{ id: 0, position: 0, text: 'Picture of a standardized test' }] },
  { id: 22, position: 22, priority: 13, takeaway: 'Recap & Summary of Sorting Algorithms', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 23, position: 23, priority: 6, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];
const engineeringLightningTalk = engineeringTED;
const engineeringPechaKucha = [
  { id: 0, position: 0, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Sorting Algorithms"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, takeaway: 'Get the audience interested in sorting algorithms', visuals: [{ id: 0, position: 0, text: 'Pictures of three businesses that are made possible by efficient sorting algorithms (Google, Airlines, and Uber)' }] },
  { id: 2, position: 2, takeaway: 'Key Metric: Time Efficiency', visuals: [{ id: 0, position: 0, text: 'Animation showing a fast algorithm and a slow algorithm working side-by-side' }] },
  { id: 3, position: 3, takeaway: 'Key Metric: Space Efficiency', visuals: [{ id: 0, position: 0, text: 'Bar graph showing the relative spatial efficiencies of popular sorting algorithms' }] },
  { id: 4, position: 4, takeaway: 'Key Metric: Stability', visuals: [{ id: 0, position: 0, text: 'Definition of stability' }] },
  { id: 5, position: 5, takeaway: 'Algorithm: Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 6, position: 6, takeaway: 'Algorithm: Selection Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 7, position: 7, takeaway: 'Algorithm: Counting Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 8, position: 8, takeaway: 'Algorithm: Merge Sort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 9, position: 9, takeaway: 'Algorithm: Quicksort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 10, position: 10, takeaway: 'Algorithm: Heapsort', visuals: [{ id: 0, position: 0, text: 'Efficiency & stability values' }] },
  { id: 11, position: 11, takeaway: 'Example Implementation: Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for insertion sort' }] },
  { id: 12, position: 12, takeaway: 'Example Implementation: Merge Sort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for merge sort' }] },
  { id: 13, position: 13, takeaway: 'Example Implementation: Quicksort', visuals: [{ id: 0, position: 0, text: 'Pseudocode for quicksort' }] },
  { id: 14, position: 14, takeaway: 'Case Study: Sorting NBA Players by Lifetime Point Totals using Quicksort', visuals: [{ id: 0, position: 0, text: 'Table of all-time points leaders' }] },
  { id: 15, position: 15, takeaway: 'Case Study: Sorting Olympic Athletes by Gold, Silver, and Bronze Medals Won using Merge Sort', visuals: [{ id: 0, position: 0, text: 'Table of all-time medal leaders' }] },
  { id: 16, position: 16, takeaway: 'Case Study: Sorting 20 Test Grades using Insertion Sort', visuals: [{ id: 0, position: 0, text: 'Picture of a gradebook' }] },
  { id: 17, position: 17, takeaway: 'Case Study: Sorting 1 Million Standardized Test Results using Counting Sort', visuals: [{ id: 0, position: 0, text: 'Picture of a standardized test' }] },
  { id: 18, position: 18, takeaway: 'Recap & Summary of Sorting Algorithms', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 19, position: 19, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];

// This function uses the 'priority' property of each takeaway
// to filter the given storyboard down to the given 'numberOfSlides'.
const getAbridgedStoryboard = (fullStoryboard, numberOfSlides) => {
  return fullStoryboard.filter(el => el.priority <= numberOfSlides);
}

const getEngineeringStoryboardClassic = numberOfSlides => {
  return getAbridgedStoryboard(engineeringClassic, numberOfSlides);
}

const getEngineeringStoryboardTED = numberOfSlides => {
  return getAbridgedStoryboard(engineeringTED, numberOfSlides);
}

const getEngineeringStoryboardLightning = numberOfSlides => {
  return getAbridgedStoryboard(engineeringLightningTalk, numberOfSlides);
}

const getEngineeringStoryboardPechaKucha = () => {
  // All Pecha Kucha presentations have 20 slides,
  // so we'll never need to abridge the full storyboard.
  return engineeringPechaKucha;
}

export const getEngineeringStoryboard = (format, numberOfSlides) => {
  switch (format) {
    case 'classic':
      return getEngineeringStoryboardClassic(numberOfSlides);
    case 'ted':
      return getEngineeringStoryboardTED(numberOfSlides);
    case 'lightning':
      return getEngineeringStoryboardLightning(numberOfSlides);
    case 'pechakucha':
      return getEngineeringStoryboardPechaKucha(numberOfSlides);
    default:
      throw Error('Improper usage of getEngineeringStoryboard: <format> not recognized');
  }
}
