import { toast } from 'react-toastify';
import Authenticator from '../assets/utils/Authenticator';

// apiActionFactory(action, apiCall, errorAction) returns an action creator that
// will 1) dispatch <action>, 2) execute the <apiCall>, then 3) dispatch <errorAction>
// if the api call fails.
export const apiActionFactory = (action, apiCall, errorAction, blocking = false, showErrorToast = true, dispatchEvenIfNotAuthenticated = true) => {
  if (!action || !apiCall || !errorAction) {
    throw new Error('Improper use of `apiActionFactory`: <action> <apiCall>, and <errorAction> must be defined.');
  }

  return payload => {
    return async dispatch => {
      // If the user isn't authenticated, then we'll skip the API call
      // and just dispatch the Redux action directly.
      if (!Authenticator.isAuthenticated()) {
        if (dispatchEvenIfNotAuthenticated) {
          dispatch(action(payload));
        }
        return payload;
      }

      // If the user is authenticated, then we'll up hit the API before / after
      // dispatching the Redux action (based on whether `blocking` is true or false).
      try {
        // Dispatch the action before making the API call if <blocking> is set to false
        if (!blocking) {
          dispatch(action(payload));
        }

        // Executes the API call, and dispatches the <errorAction> in the case of failure.
        const data = await apiCall(payload);

        if (data) {
          // If <blocking> was set to true, then we need to dispatch the action now.
          if (blocking) {
            dispatch(action(data));
          }
          return data;
        }
        return null;
      } catch (error) {
        if (showErrorToast) {
          toast.error(error.message);
        }
        dispatch(errorAction({ message: error.message }));
      }
    }
  }
}

export const getMeaningfulErrorMessage = (error, defaultMessage) => {
  // TODO add sentry stuff here
  switch (error?.response?.status) {
    case 401:
      return 'Error: Unauthorized. You must be logged in to use this feature. Please log out, refresh the page, and log in again.';
    case 403:
      return "Error: Forbidden. You don't have permission to carry out this action.";
    case 404:
      return "404 error. Resource not found.";
    case 500:
      return "Error contacting the server. You may need to refresh the page to fetch the latest data.";
    case 429:
      return 'Error: Too many requests. Please wait a few minutes, then try again.';
    case 501:
      return 'Error: This feature is not yet implemented.';
    case 408:
      return 'Error: Request timeout.';
    case 502:
    case 504:
      return 'Error: Bad gateway. Please try again.';
    case 503:
      return 'Error: This service is currently unavailable. Please try again in a moment.';
    default:
      return error.response?.data?.non_field_errors || error.response?.statusText || defaultMessage;
  }
}
