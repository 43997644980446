import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchAllAssignmentAttachmentsFromServer,
  createAssignmentAttachmentOnServer,
  deleteAssignmentAttachmentOnServer
} from '../../../api/assignmentAttachments/AssignmentAttachmentsAPI';
import { map } from 'lodash';

const assignmentAttachmentsSlice = createSlice({
  name: 'assignmentAttachments',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    populateAssignmentAttachments(state, action) {
      // Store dateCreated values as milliseconds.
      let assignmentAttachmentsWithNumericalDates = map(action.payload, assignmentAttachment => {
        if (assignmentAttachment.dateCreated) {
          assignmentAttachment.dateCreated = (new Date(assignmentAttachment.dateCreated)).valueOf();
        }
        return assignmentAttachment;
      });
      let annotatedAssignmentAttachments = map(assignmentAttachmentsWithNumericalDates, assignmentAttachment => {
        assignmentAttachment.nameLowerCase = assignmentAttachment.name.toLowerCase();
        return assignmentAttachment;
      });
      state.all = annotatedAssignmentAttachments;
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchAssignmentAttachmentsFailure(state) {
      state.isFetching = false;
      state.fetchError = true;
    },
    createAssignmentAttachment(state, action) {
      state.createError = false;
      if (action.payload.dateCreated) {
        action.payload.dateCreated = (new Date(action.payload.dateCreated)).valueOf();
      }
      state.all.push(action.payload);
    },
    createAssignmentAttachmentFailure(state) {
      state.createError = true;
    },
    deleteAssignmentAttachment(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(assignmentAttachment => assignmentAttachment.id !== action.payload.id)
    },
    deleteAssignmentAttachmentFailure(state) {
      state.deleteError = true;
    },
  }
});

export const fetchAllAssignmentAttachments = apiActionFactory(
  assignmentAttachmentsSlice.actions.populateAssignmentAttachments,
  fetchAllAssignmentAttachmentsFromServer,
  assignmentAttachmentsSlice.actions.fetchAssignmentAttachmentsFailure,
  true
);

export const createAssignmentAttachment = apiActionFactory(
  assignmentAttachmentsSlice.actions.createAssignmentAttachment,
  createAssignmentAttachmentOnServer,
  assignmentAttachmentsSlice.actions.createAssignmentAttachmentFailure,
  true
);

export const deleteAssignmentAttachment = apiActionFactory(
  assignmentAttachmentsSlice.actions.deleteAssignmentAttachment,
  deleteAssignmentAttachmentOnServer,
  assignmentAttachmentsSlice.actions.deleteAssignmentAttachmentFailure
);

export const { createAssignmentAttachment: createAssignmentAttachmentInRedux } = assignmentAttachmentsSlice.actions;

export default assignmentAttachmentsSlice.reducer;
