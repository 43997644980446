import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import LoadingPage from '../components/layout/LoadingPage';
import Home from '../components/pages/Home';
import { withTracker } from '../assets/utils/GoogleAnalytics';

// Load the routes lazily in order to make the home page load super quickly.
const SignIn = lazy(() => import('../components/pages/SignIn'));
const DemoPortal = lazy(() => import('../components/pages/DemoPortal'));
const Legal = lazy(() => import('../components/pages/Legal'));
const PedagogyBase = lazy(() => import('../components/pages/pedagogy/PedagogyBase'));
const SetUpAccount = lazy(() => import('../components/pages/setup/SetUpAccount'));
const SetUpStudent = lazy(() => import('../components/pages/setup/SetUpStudent'));
const ConsentForm = lazy(() => import('../components/pages/setup/ConsentForm'));
const StudyRecruitmentForm = lazy(() => import('../components/pages/setup/StudyRecruitmentForm'));
const UserBase = lazy(() => import('../components/pages/UserBase'));
const ErrorPage = lazy(() => import('../components/pages/ErrorPage'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <PublicRoute exact path='/' component={withTracker(Home)} isAuthenticated={this.props.isAuthenticated} redirectTo='/user' />
          <Route exact path='/index' component={withTracker(Home)} />
          {/* Users see just a single "Sign In" page for simplicity */}
          <Route exact path='/signin' component={withTracker(SignIn)} />
          <Route exact path='/demo' component={withTracker(DemoPortal)} />
          <Route exact path='/legal' component={withTracker(Legal)} />
          {/* This route will only be used by anonymous users. It shows the
              pedagogy interface without the sidebar or the dark navbar.
              The app will redirect authenticated users to /user/projects
              if they try to use this route. */}
          <PublicRoute path='/project/:activeProjectId' component={withTracker(PedagogyBase)} isAuthenticated={this.props.isAuthenticated} redirectTo='/user/projects' />
          <PrivateRoute exact path='/set-up-account' component={withTracker(SetUpAccount)} isAuthenticated={this.props.isAuthenticated} />
          <PrivateRoute exact path='/set-up-student' component={withTracker(SetUpStudent)} isAuthenticated={this.props.isAuthenticated} />
          <PrivateRoute exact path='/consent-form' component={withTracker(ConsentForm)} isAuthenticated={this.props.isAuthenticated} />
          <PrivateRoute path={['/study-recruitment', '/study-recruitment/confirmed']} component={withTracker(StudyRecruitmentForm)} isAuthenticated={this.props.isAuthenticated} />
          <PrivateRoute path='/user' component={UserBase} isAuthenticated={this.props.isAuthenticated} />
          <Route component={withTracker(ErrorPage, true)} />
        </Switch>
      </Suspense>
    )
  }
};

AppRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

export default AppRoutes;
