import React from 'react';
import { Helmet } from 'react-helmet';
import { preslySummary } from '../../assets/constants/constants';
import ClickableImage from '../common/ClickableImage';
import HowItWorks from './home/HowItWorks';
// import Examples from './home/Examples';
// import Testimonials from './home/Testimonials';
import PrivacyPolicy from './home/PrivacyPolicy';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLongArrowAltRight, faFilePowerpoint, faRandom } from '@fortawesome/free-solid-svg-icons';
// import { faShuffle } from '@fortawesome/free-regular-svg-icons';
// import { faGoogleDrive, faApple } from '@fortawesome/free-brands-svg-icons';
import { getModernImageSrc } from '../common/ModernImage';
import './Home.scss';

const ShowcasePhotoMessage = getModernImageSrc('./home/Message');
const ShowcasePhotoFeedbackBot = getModernImageSrc('./home/FeedbackBot');

// const DesignTextEnriches = getModernImageSrc('./home/DesignTextEnriches');
// const GoogleSlides = getModernImageSrc('./home/GoogleSlides');

function Home() {
  return (
    <div id='landing-page-container' className='container-fluid'>
      <Helmet>
        <title>Presly</title>
      </Helmet>
      <div className='row'>
        <div className='col-11 offset-1 col-lg-9 offset-lg-2'>
          <h1 id='intro-text-large' className='my-2'>A new kind of presentation coach</h1>
        </div>
      </div>
      <div id='showcase-photo-row' className='row mt-2 mt-lg-3'>
        <div className='col-12 col-md-10 offset-md-1'>
          <div className='showcase-photo-container'>
            <ClickableImage
              key='showcase-photo-message'
              uid='showcase-photo-message'
              className='showcase-photo-message'
              src={ShowcasePhotoMessage}
              alt="Presly teaches you the ingredients that go into the most successful presentations, like having a clear, memorable, and useful message."
            />
            <ClickableImage
              key='showcase-photo-feedback-bot'
              uid='showcase-photo-feedback-bot'
              className='showcase-photo-feedback-bot'
              src={ShowcasePhotoFeedbackBot}
              alt="Presly's feedback bot provides real-time feedback on your delivery skills."
            />
          </div>
        </div>
      </div>
      <div id='summary-text-row' className='row'>
        <div className='col-10 offset-1 col-md-10 offset-md-1 text-center'>
          <span id='summary-text'>{preslySummary}</span>
        </div>
      </div>
      <HowItWorks />
      {/* <Testimonials /> */}
      <PrivacyPolicy />
    </div>
  );
}

export default Home;
