import React, { Component } from 'react';
import './HowItWorks.scss';
import ClickableImage from '../../common/ClickableImage';
import { getModernImageSrc } from '../../common/ModernImage';

const PlanPhoto = getModernImageSrc('./home/Plan');
const CreatePhoto = getModernImageSrc('./home/Create');
const DesignPhoto = getModernImageSrc('./home/Design');
const DeliverPhoto = getModernImageSrc('./home/Deliver');

class HowItWorks extends Component {
  render() {
    return (
      <div id='how-it-works-container' className='row'>
        <div className='col-10 offset-1 col-md-12 offset-md-0'>
          {/* <div className='row'>
            <div id='how-it-works-title' className='col-10 offset-1 col-md-8 offset-md-2 text-center'>Four steps to success</div>
          </div> */}
          <div className='row'>
            <div className='col-12 text-center how-it-works-title'>
              How it works
            </div>
          </div>
          <div className='row mt-2 mt-lg-3 mb-5 text-left how-it-works-directions-text'>
            <div className='col-12 col-md-10 offset-md-1'>
              <ol>
                <li className='pl-2 pl-lg-3'>
                  First, you use Presly to outline your presentation and learn new skills.
                </li>
                <li className='pl-2 pl-lg-3 mb-md-2 mb-xl-4'>
                  Then, use your favorite slideshow software to build your presentation!
                </li>
              </ol>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-10 offset-md-1'>
              <div className='row how-it-works-section'>
                <div className='col-12 col-sm-6 col-lg-5 my-auto'>
                  <div className='row'>
                    <div className='col-12 step-header'>
                      1.&nbsp;&nbsp;Plan
                    </div>
                  </div>
                  <div className='row'>
                    <div className='step-description col-12'>
                      Plan out your presentation by thinking about your audience, message, and purpose.
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-6 offset-lg-1 mt-4 my-sm-auto'>
                  <div className='row'>
                    <div className='col-12'>
                      <ClickableImage key='home-plan-photo' uid='home-plan-photo' src={PlanPhoto} alt='Plan out your presenation.' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row how-it-works-section'>
                <div className='col-12 col-sm-6 col-lg-5 offset-lg-1 my-auto order-sm-last'>
                  <div className='row'>
                    <div className='col-12 step-header'>
                      2.&nbsp;&nbsp;Create
                    </div>
                  </div>
                  <div className='row'>
                    <div className='step-description col-12'>
                      Decide how to format your presentation, and storyboard your content.
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-6 mt-4 my-sm-auto order-sm-first'>
                  <div className='row'>
                    <div className='col-12'>
                      <ClickableImage key='home-create-photo' uid='home-create-photo' src={CreatePhoto} alt='Create the content for your presentation.' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row how-it-works-section'>
                <div className='col-12 col-sm-6 col-lg-5 my-auto'>
                  <div className='row'>
                    <div className='col-12 step-header'>
                      3.&nbsp;&nbsp;Design
                    </div>
                  </div>
                  <div className='row'>
                    <div className='step-description col-12'>
                      Take your visuals to the next level. Explore examples that illustrate good design techniques.
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-6 offset-lg-1 mt-4 my-sm-auto'>
                  <div className='row'>
                    <div className='col-12'>
                      <ClickableImage key='home-design-photo' uid='home-design-photo' src={DesignPhoto} alt='Design your presentation.' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row how-it-works-section mb-0'>
                <div className='col-12 col-sm-6 col-lg-5 offset-lg-1 my-auto order-sm-last'>
                  <div className='row'>
                    <div className='col-12 step-header'>
                      4.&nbsp;&nbsp;Deliver
                    </div>
                  </div>
                  <div className='row'>
                    <div className='step-description col-12'>
                      Record yourself delivering your presentation. Presly gives you feedback on pacing, clarity, word choice, and more!
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-6 mt-4 my-sm-auto order-sm-first'>
                  <div className='row'>
                    <div className='col-12'>
                      <ClickableImage key='home-deliver-photo' uid='home-deliver-photo' src={DeliverPhoto} alt='Practice delivering your presentation.' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
