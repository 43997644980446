import React from 'react';
import { isPlainObject } from 'lodash';
import path from 'path';

// This if statement detects if we're in a Node environment (i.e. if we're running tests),
// and if we are, it uses a dynamic require statement based on the node path instead of
// require.context, which is a Webpack function.
let imageImport;
if (process.env.NODE_ENV === 'test') {
  imageImport = file => require(path.resolve(__dirname, '../../assets/imgs', file));
} else {
  imageImport = require.context('../../assets/imgs', true);
}

export const DynamicImageImport = imageImport;

// Returns a map of images to use depending on the browser.
// The provided <imagePath> should be relative to the assets/imgs folder.
export const getModernImageSrc = (imagePath, useJp2 = true, useWebp = true) => {
  return {
    jp2: useJp2 && DynamicImageImport(`${imagePath}Optimized.jp2`),
    webp: useWebp && DynamicImageImport(`${imagePath}Optimized.webp`),
    png: DynamicImageImport(`${imagePath}Optimized.png`)
  }
}

// ModernImages components can serve either images or looping videos
// Looping videos are basically just GIFs, but we use the mp4 & WebM formats here instead.
const ModernImage = ({ src, alt = '', ...props }) => {
  if (isPlainObject(src)) {
    // I.e. src is a map of image sources that work on different browsers.
    return <picture>
      {/* Safari */}
      {!!src.jp2 &&
        <source srcSet={src.jp2} type='image/jp2' />
      }
      {/* Most browsers */}
      {!!src.webp &&
        <source srcSet={src.webp} type='image/webp' />
      }

      {/* Fallback image for all browsers */}
      {!!src.png && <>
        <source srcSet={src.png} type='image/png' />
        <img src={src.png} alt={alt} {...props} />
      </>}
    </picture>
  } else {
    // I.e. src is actually just a single image.
    return <img src={src} alt={alt} {...props} />
  }
}

export default ModernImage;
