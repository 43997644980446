import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchCoursesFromServer,
  createCourseOnServer,
  duplicateCourseOnServer,
  updateCourseOnServer,
  createCourseSectionOnServer,
  deleteCourseOnServer,
  removeCurrentUserFromCourseOnServer,
  registerForCourseSectionOnServer,
  renameCourseSectionOnServer,
  deleteCourseSectionOnServer
} from '../../../api/courses/CoursesAPI';

const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    all: [],
    isFetching: true
  },
  reducers: {
    createCourse(state, action) {
      state.createError = false;
      state.all.push(action.payload)
    },
    createCourseFailure(state) {
      state.createError = true;
    },
    registerForCourse(state, action) {
      state.registrationError = false;
      // First remove the course if it's already here
      state.all = state.all.filter(course => course.id !== action.payload.id)
      // Now add the course
      state.all.push(action.payload)
    },
    registerForCourseFailure(state) {
      state.registrationError = true;
    },
    removeCurrentUserFromCourse(state, action) {
      state.removeCurrentUserFromCourseFailure = false;
      state.all = state.all.filter(course => course.id !== action.payload.id)
      // TODO implement section-by-section deregistration as well.
      // state.all = state.all.filter(course => course.sections[0].id !== action.payload.id)
    },
    removeCurrentUserFromCourseFailure(state) {
      state.removeCurrentUserFromCourseFailure = true;
    },
    updateCourse(state, action) {
      state.updateError = false;
      let course = state.all.find(course => course.id === action.payload.id);
      for (const [key, value] of Object.entries(action.payload)) {
        course[key] = value;
      }
    },
    updateCourseFailure(state) {
      state.updateError = true;
    },
    deleteCourse(state, action) {
      state.deleteError = false;
      state.all = state.all.filter(course => course.id !== action.payload.id)
    },
    deleteCourseFailure(state) {
      state.deleteError = true;
    },
    populateCourses(state, action) {
      state.all = action.payload.courses
      state.isFetching = false;
      state.fetchError = false;
    },
    fetchCoursesFailure(state) {
      state.fetchError = true;
    },
    setActiveCourse(state, action) {
      state.activeCourseId = action.payload.id
    },
    setActiveSection(state, action) {
      state.activeSectionId = action.payload.id
    },
    setActiveAssignment(state, action) {
      state.activeAssignmentId = action.payload.id
    },
    createCourseSection(state, action) {
      state.createCourseSectionError = false;
      let course = state.all.find(course => course.id === action.payload.course)
      if (course) {
        course.sections.push(action.payload);
      }
    },
    createCourseSectionFailure(state) {
      state.createCourseSectionError = true;
    },
    renameCourseSection(state, action) {
      state.renameCourseSectionError = false;
      state.all.forEach(course => {
        let section = course.sections.find(section => section.id === action.payload.id);
        if (section) {
          section.name = action.payload.name;
        }
      });
    },
    renameCourseSectionFailure(state) {
      state.renameCourseSectionError = true;
    },
    deleteCourseSection(state, action) {
      state.deleteCourseSectionError = false;
      let course = state.all.find(course => course.sections.find(section => section.id === action.payload.id));
      course.sections = course.sections.filter(section => section.id !== action.payload.id);
    },
    deleteCourseSectionFailure(state) {
      state.deleteCourseSectionError = true;
    },
  }
})


export const createCourse = apiActionFactory(
  coursesSlice.actions.createCourse,
  createCourseOnServer,
  coursesSlice.actions.createCourseFailure,
  true
);

export const duplicateCourse = apiActionFactory(
  coursesSlice.actions.createCourse,
  duplicateCourseOnServer,
  coursesSlice.actions.createCourseFailure,
  true
);

export const registerForCourse = apiActionFactory(
  coursesSlice.actions.registerForCourse,
  registerForCourseSectionOnServer,
  coursesSlice.actions.registerForCourseFailure,
  true
);

export const updateCourse = apiActionFactory(
  coursesSlice.actions.updateCourse,
  updateCourseOnServer,
  coursesSlice.actions.updateCourseFailure,
  true
);

export const deleteCourse = apiActionFactory(
  coursesSlice.actions.deleteCourse,
  deleteCourseOnServer,
  coursesSlice.actions.deleteCourseFailure,
  true
);

export const removeCurrentUserFromCourse = apiActionFactory(
  coursesSlice.actions.removeCurrentUserFromCourse,
  removeCurrentUserFromCourseOnServer,
  coursesSlice.actions.removeCurrentUserFromCourseFailure
);

export const fetchCourses = apiActionFactory(
  coursesSlice.actions.populateCourses,
  fetchCoursesFromServer,
  coursesSlice.actions.fetchCoursesFailure,
  true
);

export const createCourseSection = apiActionFactory(
  coursesSlice.actions.createCourseSection,
  createCourseSectionOnServer,
  coursesSlice.actions.createCourseSectionFailure,
  true
);

export const renameCourseSection = apiActionFactory(
  coursesSlice.actions.renameCourseSection,
  renameCourseSectionOnServer,
  coursesSlice.actions.renameCourseSectionFailure,
);

export const deleteCourseSection = apiActionFactory(
  coursesSlice.actions.deleteCourseSection,
  deleteCourseSectionOnServer,
  coursesSlice.actions.deleteCourseSectionFailure,
);

export const { setActiveCourse, setActiveSection, setActiveAssignment } = coursesSlice.actions

export default coursesSlice.reducer
