// The sections of the full humanities storyboard are:
// (3 slides) intro -- title, overview of presentation, outline of presentation
// (3 slides) historical context -- Age of Reason, timeline of Romanticism, Victorian period
// (3 slides) values -- nature vs. civilization, innocence of children, nostalgia
// (2 slides) aesthetics -- the sublime, the picturesque
// (7 slides) major authors -- William Wordsworth, Samuel Taylor Coleridge, Robert Southey, Lord Byron, Percy Shelley, Mary Shelley, John Keats
// (4 slides) international movements -- French (Hugo), German (Goethe), Scottish (Scott), American (Poe)
// (2 slides) conclusion -- summary, thank you + questions
const humanitiesClassic = [
  { id: 0, position: 0, priority: 1, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Introduction to Romanticism"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, priority: 9, takeaway: 'Get the audience interested in Romanticism', visuals: [{ id: 0, position: 0, text: 'Provocative quote about Romanticism' }] },
  { id: 2, position: 2, priority: 12, takeaway: 'Outline the sections of my presentation', visuals: [{ id: 0, position: 0, text: 'List of numbered text boxes (one for each section)' }] },
  { id: 3, position: 3, priority: 10, takeaway: 'Describe the historical context that gave rise to Romanticism', visuals: [{ id: 0, position: 0, text: 'Overview of the Enlightenment' }, { id: 1, position: 1, text: 'Picture of Isaac Newton' }] },
  { id: 4, position: 4, priority: 2, takeaway: 'Outline the history of the Romantic period', visuals: [{ id: 0, position: 0, text: 'Timeline' }] },
  { id: 5, position: 5, priority: 11, takeaway: 'Romanticism led to the Victorian Period', visuals: [{ id: 0, position: 0, text: 'List of Victorian values' }, { id: 1, position: 1, text: 'Picture of Queen Victoria' }] },
  { id: 6, position: 6, priority: 3, takeaway: 'Romantic value: Nature is better than civilization', visuals: [{ id: 0, position: 0, text: 'Romantic painting of the countryside' }, { id: 1, position: 1, text: 'Quote from Wordsworth about nature' }] },
  { id: 7, position: 7, priority: 7, takeaway: 'Romantic value: Children are born innocent', visuals: [{ id: 0, position: 0, text: 'Picture of Blake\'s "Songs of Innocence"' }, { id: 1, position: 1, text: 'Quote about children' }] },
  { id: 8, position: 8, priority: 8, takeaway: 'Romantic value: Nostalgia for a simpler past', visuals: [{ id: 0, position: 0, text: 'Painting of industrialized London' }, { id: 1, position: 1, text: 'Quote about the past' }] },
  { id: 9, position: 9, priority: 4, takeaway: 'Aesthetics: Focus on the sublime', visuals: [{ id: 0, position: 0, text: 'Painting illustrating the sublime' }, { id: 1, position: 1, text: 'Definition of the sublime' }] },
  { id: 10, position: 10, priority: 5, takeaway: 'Aesthetics: Focus on the picturesque', visuals: [{ id: 0, position: 0, text: 'Painting illustrating the picturesque' }, { id: 1, position: 1, text: 'Definition of the picturesque' }] },
  { id: 11, position: 11, priority: 14, takeaway: 'Major Author: William Wordsworth', visuals: [{ id: 0, position: 0, text: 'Picture of Wordsworth' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 12, position: 12, priority: 15, takeaway: 'Major Author: Samuel Taylor Coleridge', visuals: [{ id: 0, position: 0, text: 'Picture of Coleridge' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 13, position: 13, priority: 20, takeaway: 'Major Author: Robert Southey', visuals: [{ id: 0, position: 0, text: 'Picture of Southey' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 14, position: 14, priority: 16, takeaway: 'Major Author: Lord Byron', visuals: [{ id: 0, position: 0, text: 'Picture of Byron' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 15, position: 15, priority: 17, takeaway: 'Major Author: Percy Shelley', visuals: [{ id: 0, position: 0, text: 'Picture of P. Shelley' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 16, position: 16, priority: 18, takeaway: 'Major Author: Mary Shelley', visuals: [{ id: 0, position: 0, text: 'Picture of M. Shelley' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 17, position: 17, priority: 19, takeaway: 'Major Author: John Keats', visuals: [{ id: 0, position: 0, text: 'Picture of Keats' }, { id: 1, position: 1, text: 'List of key works and ideas' }] },
  { id: 18, position: 18, priority: 21, takeaway: 'International Romanticism: French', visuals: [{ id: 0, position: 0, text: 'Delacroix Painting (Liberty Leading the People)' }, { id: 1, position: 1, text: 'List of major figures' }] },
  { id: 19, position: 19, priority: 22, takeaway: 'International Romanticism: German', visuals: [{ id: 0, position: 0, text: 'Painting by Caspar David Friedrich' }, { id: 1, position: 1, text: 'List of major figures' }] },
  { id: 20, position: 20, priority: 23, takeaway: 'International Romanticism: American', visuals: [{ id: 0, position: 0, text: 'Picture of Walden (by Thoreau)' }, { id: 1, position: 1, text: 'List of major figures' }] },
  { id: 21, position: 21, priority: 24, takeaway: 'International Romanticism: Scottish', visuals: [{ id: 0, position: 0, text: 'Picture of Sir Walter Scott' }, { id: 1, position: 1, text: 'List of major figures' }] },
  { id: 22, position: 22, priority: 13, takeaway: 'Recap & Summary of Romanticism', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 23, position: 23, priority: 6, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];
const humanitiesTED = [
  { id: 0, position: 0, priority: 1, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Introduction to Romanticism"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, priority: 9, takeaway: 'Get the audience interested in Romanticism', visuals: [{ id: 0, position: 0, text: 'Provocative quote about Romanticism' }] },
  { id: 2, position: 2, priority: 12, takeaway: 'Outline the sections of my presentation', visuals: [{ id: 0, position: 0, text: 'None' }] },
  { id: 3, position: 3, priority: 10, takeaway: 'Describe the historical context that gave rise to Romanticism', visuals: [{ id: 0, position: 0, text: 'Picture of Isaac Newton' }] },
  { id: 4, position: 4, priority: 2, takeaway: 'Outline the history of the Romantic period', visuals: [{ id: 0, position: 0, text: 'Timeline' }] },
  { id: 5, position: 5, priority: 11, takeaway: 'Romanticism led to the Victorian Period', visuals: [{ id: 0, position: 0, text: 'Picture of Queen Victoria' }] },
  { id: 6, position: 6, priority: 3, takeaway: 'Romantic value: Nature is better than civilization', visuals: [{ id: 0, position: 0, text: 'Quote from Wordsworth about nature' }] },
  { id: 7, position: 7, priority: 7, takeaway: 'Romantic value: Children are born innocent', visuals: [{ id: 0, position: 0, text: 'Picture of Blake\'s "Songs of Innocence"' }] },
  { id: 8, position: 8, priority: 8, takeaway: 'Romantic value: Nostalgia for a simpler past', visuals: [{ id: 0, position: 0, text: 'Painting of industrialized London' }] },
  { id: 9, position: 9, priority: 4, takeaway: 'Aesthetics: Focus on the sublime', visuals: [{ id: 0, position: 0, text: 'Painting illustrating the sublime' }] },
  { id: 10, position: 10, priority: 5, takeaway: 'Aesthetics: Focus on the picturesque', visuals: [{ id: 0, position: 0, text: 'Painting illustrating the picturesque' }] },
  { id: 11, position: 11, priority: 14, takeaway: 'Major Author: William Wordsworth', visuals: [{ id: 0, position: 0, text: 'Picture of Wordsworth' }] },
  { id: 12, position: 12, priority: 15, takeaway: 'Major Author: Samuel Taylor Coleridge', visuals: [{ id: 0, position: 0, text: 'Picture of Coleridge' }] },
  { id: 13, position: 13, priority: 20, takeaway: 'Major Author: Robert Southey', visuals: [{ id: 0, position: 0, text: 'Picture of Southey' }] },
  { id: 14, position: 14, priority: 16, takeaway: 'Major Author: Lord Byron', visuals: [{ id: 0, position: 0, text: 'Picture of Byron' }] },
  { id: 15, position: 15, priority: 17, takeaway: 'Major Author: Percy Shelley', visuals: [{ id: 0, position: 0, text: 'Picture of P. Shelley' }] },
  { id: 16, position: 16, priority: 18, takeaway: 'Major Author: Mary Shelley', visuals: [{ id: 0, position: 0, text: 'Picture of M. Shelley' }] },
  { id: 17, position: 17, priority: 19, takeaway: 'Major Author: John Keats', visuals: [{ id: 0, position: 0, text: 'Picture of Keats' }] },
  { id: 18, position: 18, priority: 21, takeaway: 'International Romanticism: French', visuals: [{ id: 0, position: 0, text: 'Delacroix Painting (Liberty Leading the People)' }] },
  { id: 19, position: 19, priority: 22, takeaway: 'International Romanticism: German', visuals: [{ id: 0, position: 0, text: 'Painting by Caspar David Friedrich' }] },
  { id: 20, position: 20, priority: 23, takeaway: 'International Romanticism: American', visuals: [{ id: 0, position: 0, text: 'Picture of Walden (by Thoreau)' }] },
  { id: 21, position: 21, priority: 24, takeaway: 'International Romanticism: Scottish', visuals: [{ id: 0, position: 0, text: 'Picture of Sir Walter Scott' }] },
  { id: 22, position: 22, priority: 13, takeaway: 'Recap & Summary of Romanticism', visuals: [{ id: 0, position: 0, text: 'Text boxes recapping the main ideas' }] },
  { id: 23, position: 23, priority: 6, takeaway: 'Thank you + Questions', visuals: [{ id: 0, position: 0, text: 'Thank you' }, { id: 1, position: 1, text: 'Questions?' }] }
];
const humanitiesLightningTalk = humanitiesTED;
const humanitiesPechaKucha = [
  { id: 0, position: 0, takeaway: 'Introduce myself and my topic', visuals: [{ id: 0, position: 0, text: 'Title: "Introduction to Romanticism"' }, { id: 1, position: 1, text: 'My name' }] },
  { id: 1, position: 1, takeaway: 'Get the audience interested in Romanticism', visuals: [{ id: 0, position: 0, text: 'J. M. W. Turner Painting' }] },
  { id: 2, position: 2, takeaway: 'Describe the historical context that gave rise to Romanticism', visuals: [{ id: 0, position: 0, text: 'Picture of Isaac Newton' }] },
  { id: 3, position: 3, takeaway: 'Outline the history of the Romantic period', visuals: [{ id: 0, position: 0, text: 'Picture of Wordsworth' }] },
  { id: 4, position: 4, takeaway: 'Romanticism led to the Victorian Period', visuals: [{ id: 0, position: 0, text: 'Picture of Queen Victoria' }] },
  { id: 5, position: 5, takeaway: 'Romantic value: Nature is better than civilization', visuals: [{ id: 0, position: 0, text: 'Painting of the English countryside' }] },
  { id: 6, position: 6, takeaway: 'Romantic value: Children are born innocent', visuals: [{ id: 0, position: 0, text: 'Picture of Blake\'s "Songs of Innocence"' }] },
  { id: 7, position: 7, takeaway: 'Romantic value: Nostalgia for a simpler past', visuals: [{ id: 0, position: 0, text: 'Painting of industrialized London' }] },
  { id: 8, position: 8, takeaway: 'Aesthetics: Focus on the sublime', visuals: [{ id: 0, position: 0, text: 'Painting illustrating the sublime' }] },
  { id: 9, position: 9, takeaway: 'Aesthetics: Focus on the picturesque', visuals: [{ id: 0, position: 0, text: 'Painting illustrating the picturesque' }] },
  { id: 10, position: 10, takeaway: 'Major Author: William Wordsworth', visuals: [{ id: 0, position: 0, text: 'Picture of Wordsworth (different from previous)' }] },
  { id: 11, position: 11, takeaway: 'Major Author: Samuel Taylor Coleridge', visuals: [{ id: 0, position: 0, text: 'Picture of Coleridge' }] },
  { id: 12, position: 12, takeaway: 'Major Author: Lord Byron', visuals: [{ id: 0, position: 0, text: 'Picture of Byron' }] },
  { id: 13, position: 13, takeaway: 'Major Author: Percy Shelley', visuals: [{ id: 0, position: 0, text: 'Picture of P. Shelley' }] },
  { id: 14, position: 14, takeaway: 'Major Author: Mary Shelley', visuals: [{ id: 0, position: 0, text: 'Picture of M. Shelley' }] },
  { id: 15, position: 15, takeaway: 'Major Author: John Keats', visuals: [{ id: 0, position: 0, text: 'Picture of Keats' }] },
  { id: 16, position: 16, takeaway: 'International Romanticism: French', visuals: [{ id: 0, position: 0, text: 'Delacroix Painting (Liberty Leading the People)' }] },
  { id: 17, position: 17, takeaway: 'International Romanticism: German', visuals: [{ id: 0, position: 0, text: 'Painting by Caspar David Friedrich' }] },
  { id: 18, position: 18, takeaway: 'Recap & Summary of Romanticism', visuals: [{ id: 0, position: 0, text: 'Another J. M. W. Turner painting' }] },
  { id: 19, position: 19, takeaway: 'Thank you', visuals: [{ id: 0, position: 0, text: '"Thank you"' }] }
];

// This function uses the 'priority' property of each takeaway
// to filter the given storyboard down to the given 'numberOfSlides'.
const getAbridgedStoryboard = (fullStoryboard, numberOfSlides) => {
  return fullStoryboard.filter(el => el.priority <= numberOfSlides);
}

const getHumanitiesStoryboardClassic = numberOfSlides => {
  return getAbridgedStoryboard(humanitiesClassic, numberOfSlides);
}

const getHumanitiesStoryboardTED = numberOfSlides => {
  return getAbridgedStoryboard(humanitiesTED, numberOfSlides);
}

const getHumanitiesStoryboardLightning = numberOfSlides => {
  return getAbridgedStoryboard(humanitiesLightningTalk, numberOfSlides);
}

const getHumanitiesStoryboardPechaKucha = () => {
  // All Pecha Kucha presentations have 20 slides,
  // so we'll never need to abridge the full storyboard.
  return humanitiesPechaKucha;
}

export const getHumanitiesStoryboard = (format, numberOfSlides) => {
  switch (format) {
    case 'classic':
      return getHumanitiesStoryboardClassic(numberOfSlides);
    case 'ted':
      return getHumanitiesStoryboardTED(numberOfSlides);
    case 'lightning':
      return getHumanitiesStoryboardLightning(numberOfSlides);
    case 'pechakucha':
      return getHumanitiesStoryboardPechaKucha(numberOfSlides);
    default:
      throw Error('Improper use of getHumanitiesStoryboard: <format> not recognized');
  }
}
