import React, { useMemo, useState } from 'react';
import { truncate } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/features/user/userSelector';
import { getActiveProject, getProjects } from '../../redux/features/projects/projectsSelector';
import { getCourses, getActiveCourse } from '../../redux/features/courses/coursesSelector';
import { trackNavbarEvent, trackPreslyFeedbackModalEvent } from '../../assets/utils/GoogleAnalytics';
import { executeOnEnter } from '../../assets/utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import ModernImage, { getModernImageSrc } from '../common/ModernImage';
import SubjectManager from '../../assets/constants/SubjectManager';
import ProjectSelector from './navbar/ProjectSelector';
import CourseSelector from './navbar/CourseSelector';
import SubjectSelector from './navbar/SubjectSelector';
import UserDropdown from './navbar/UserDropdown';
import PreslyFeedbackModal from '../common/PreslyFeedbackModal';
import { ANONYMOUS_USER_PROJECT_ID } from '../../assets/constants/constants';
import './Navbar.scss';

// I've currently disabled the JP2 version of the logo, because
// it's having issues with transparency.
const PreslyLogo = getModernImageSrc('./home/PreslyLogo', false);

const getTruncatedCourseName = (courseName, windowWidth) => {
  if (!courseName) return '';
  return truncate(courseName, { length: windowWidth / 20 });
}


const Navbar = ({ isAuthenticated }) => {
  const projects = useSelector(getProjects);
  const activeProject = useSelector(getActiveProject);
  const courses = useSelector(getCourses);
  const activeCourse = useSelector(getActiveCourse);
  const currentUser = useSelector(getUser);
  const location = useLocation();
  const windowWidth = useWindowWidth();
  const [isSelectingProject, setIsSelectingProject] = useState(false);
  const [isSelectingCourse, setIsSelectingCourse] = useState(false);
  const [isSelectingSubject, setIsSelectingSubject] = useState(false);
  const [isViewingUserDropdown, setIsViewingUserDropdown] = useState(false);
  const [preslyFeedbackModalIsShowing, setPreslyFeedbackModalIsShowing] = useState(false);

  const isOnProjectPage = useMemo(() => location.pathname.includes('/user/project/') || location.pathname.startsWith('/project/'), [location.pathname]);
  const isOnCourseDashboard = useMemo(() => location.pathname.includes('/user/course/'), [location.pathname]);
  const truncatedActiveCourseName = useMemo(() => getTruncatedCourseName(activeCourse?.name, windowWidth), [activeCourse, windowWidth]);

  const navbarClasses = useMemo(() => {
    if (isAuthenticated) {
      return 'navbar-dark compact';
    } else if (location.pathname.startsWith('/project/')) {
      return 'navbar-dark compact';
    }
    return 'navbar-light';
  }, [location.pathname, isAuthenticated]);

  const activeSubjectText = useMemo(() => {
    if (!activeProject?.subject || activeProject?.subject === SubjectManager.NULL_SUBJECT) {
      // Default to 'Humanities' if the subject isn't specified.
      return 'Humanities';
    }
    return SubjectManager.DEFAULT_SUBJECTS[activeProject.subject];
  }, [activeProject]);

  const handlePreslyFeedbackModalOpen = () => {
    setPreslyFeedbackModalIsShowing(true);
  }

  const handlePreslyFeedbackModalClose = () => {
    setPreslyFeedbackModalIsShowing(false);
    trackPreslyFeedbackModalEvent('Closed Modal');
  }

  return <>
    <nav
      data-testid='navbar'
      id='presly-navbar'
      className={`navbar navbar-expand-sm fixed-top ${navbarClasses}`}
      aria-label='Primary Navigation Bar'
    >
      <div className='container-fluid'>
        {/* Logo & Title */}
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <Link className='navbar-brand mr-2' to='/index'>
              {/* 32px & 20px */}
              <ModernImage
                id='navbar-logo'
                src={PreslyLogo}
                alt='The Presly Owl'
                aria-hidden='true'
                onClick={() => trackNavbarEvent('Clicked Logo Icon')}
              />
              <span id='navbar-title' className='ml-2'
                onClick={() => trackNavbarEvent('Clicked Logo Text')}>
                Presly
              </span>
            </Link>
          </li>
        </ul>
        {/* Project Selector Button */}
        {isAuthenticated && isOnProjectPage && activeProject &&
          <ul className='navbar-nav navbar-logo mx-auto project-menu'>
            <li className='nav-item no-select'>
              {!isSelectingProject &&
                <span onClick={() => {
                  trackNavbarEvent('Clicked Project Selector');
                  setIsSelectingProject(true)
                }} onKeyPress={event => executeOnEnter(event, () => {
                  trackNavbarEvent('Clicked Project Selector');
                  setIsSelectingProject(!isSelectingProject);
                })} aria-label='Project selector'
                  tabIndex='0'>
                  {activeProject?.title}
                  <FontAwesomeIcon className='ml-2' icon={faCaretDown} />
                </span>
              }
            </li>
          </ul>
        }
        {/* Subject Selector Button */}
        {!isAuthenticated && isOnProjectPage && activeProject?.id === ANONYMOUS_USER_PROJECT_ID &&
          <ul className='navbar-nav navbar-logo mx-auto subject-menu'>
            <li className='nav-item no-select'>
              {!isSelectingSubject &&
                <span onClick={() => {
                  trackNavbarEvent('Clicked Subject Selector');
                  setIsSelectingSubject(true)
                }} onKeyPress={event => executeOnEnter(event, () => {
                  trackNavbarEvent('Clicked Subject Selector');
                  setIsSelectingSubject(!isSelectingSubject);
                })} aria-label='Subject selector'
                  tabIndex='0'>
                  {activeSubjectText}
                  <FontAwesomeIcon className='ml-2' icon={faCaretDown} />
                </span>
              }
            </li>
          </ul>
        }
        {/* Course Selector Button */}
        {isAuthenticated && isOnCourseDashboard && activeCourse &&
          <ul className='navbar-nav navbar-logo mx-auto course-menu'>
            <li className='nav-item no-select'>
              {!isSelectingCourse &&
                <span onClick={() => {
                  trackNavbarEvent('Clicked Course Selector');
                  setIsSelectingCourse(true);
                }} onKeyPress={event => executeOnEnter(event, () => {
                  trackNavbarEvent('Clicked Course Selector');
                  setIsSelectingCourse(!isSelectingCourse);
                })} aria-label='Course selector'
                  tabIndex='0'>
                  {truncatedActiveCourseName}
                  <FontAwesomeIcon className='ml-2' icon={faCaretDown} />
                </span>
              }
            </li>
          </ul>
        }
        {/* User Name & Icon / Sign In Button */}
        {isAuthenticated ?
          <ul id='navbar-links' className='navbar-nav ml-auto mr-0'>
            <li
              id='user-icon-container'
              className='nav-item mr-sm-2 mr-md-3 mr-lg-4 no-select'
              onClick={() => setIsViewingUserDropdown(true)}
              onKeyPress={event => executeOnEnter(event, () => {
                setIsViewingUserDropdown(!isViewingUserDropdown);
              })}
              aria-label='User dropdown menu'
            >
              {!!currentUser.firstName &&
                <div id='user-name-container'>
                  <div onClick={() => trackNavbarEvent('Clicked User Name')}>
                    {currentUser.firstName}
                  </div>
                </div>
              }
              <FontAwesomeIcon
                onClick={() => trackNavbarEvent('Clicked User Icon')}
                icon={faUserCircle}
                tabIndex='0'
                aria-label='User dropdown icon'
                aria-hidden={false} />
            </li>
          </ul>
          : <ul id='navbar-links' className='navbar-nav ml-auto mr-0 verbose-navbar-links'>
            <li id='navbar-use-without-account-btn' className='nav-item'>
              {/* Let's hide the "Use Without An Account" / "Use Now" link when the user is using Presly anonymously & is on a pedagogy page */}
              <Link
                className={`nav-link ${!isAuthenticated && isOnProjectPage && activeProject?.id === ANONYMOUS_USER_PROJECT_ID ? 'd-none' : ''}`}
                to={`/project/${ANONYMOUS_USER_PROJECT_ID}/plan`}
                onClick={() => trackNavbarEvent('Clicked Use Without An Account')}
              >
                <span className='d-sm-none'>Use&nbsp;Now</span>
                <span className='d-none d-sm-inline'>Use&nbsp;Without&nbsp;An&nbsp;Account</span>
              </Link>
            </li>
            {/* <li id='navbar-demo-btn' className='nav-item'>
              <Link
                className='nav-link'
                to='/demo'
                onClick={() => trackNavbarEvent('Clicked Live Demo')}
              >
                Live&nbsp;Demo
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                id='navbar-sign-in-btn'
                className='nav-link'
                to='/signin'
                onClick={() => trackNavbarEvent('Clicked Sign In')}
              >
                Sign&nbsp;In
              </Link>
            </li>
          </ul>
        }
      </div>
    </nav>
    {isSelectingProject &&
      <ProjectSelector
        projects={projects}
        activeProject={activeProject}
        onSelect={() => setIsSelectingProject(false)}
      />
    }
    {isSelectingCourse &&
      <CourseSelector
        courses={courses}
        activeCourse={activeCourse}
        onSelect={() => setIsSelectingCourse(false)}
      />
    }
    {isSelectingSubject &&
      <SubjectSelector
        activeProject={activeProject}
        onSelect={() => setIsSelectingSubject(false)}
      />
    }
    {isViewingUserDropdown &&
      <UserDropdown
        user={currentUser}
        handlePreslyFeedbackModalOpen={handlePreslyFeedbackModalOpen}
        onSelect={() => setIsViewingUserDropdown(false)}
      />
    }
    <PreslyFeedbackModal
      modalIsShowing={preslyFeedbackModalIsShowing}
      handleModalClose={handlePreslyFeedbackModalClose}
    />
  </>
}

export default Navbar;
