import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

// This utility class allows me to easily reuse code for all of the different checklists
// @param resourceName - The way the resource appears in the API route (e.g. "audience-field-checklist")
export default function (resourceName) {
  const updateFieldOnServer = async function (payload, value) {
    if (!payload.hasOwnProperty('projectId') || !payload.hasOwnProperty('item')) {
      throw new Error('Missing Parameter: payload must contain <projectId> and <item>');
    }

    try {
      let response = await axios.put('/api/projects/' + payload.projectId + '/' + resourceName, { [payload.item]: value });
      if (response.data.checklist) {
        return response.data.checklist;
      } else {
        throw new Error('Error: Failed to update the checklist');
      }
    } catch (error) {
      throw new Error(getMeaningfulErrorMessage(error, 'Error: PUT /api/projects/' + payload.projectId + '/' + resourceName + 'failed'));
    }
  };

  return {
    checkItemOnServer: async payload => {
      return updateFieldOnServer(payload, true);
    },
    uncheckItemOnServer: async payload => {
      return updateFieldOnServer(payload, false);
    },
    // Returns the checklist for the given project
    fetchItemsFromServer: async payload => {
      try {
        let response = await axios.get('/api/projects/' + payload.projectId + '/' + resourceName);
        return response.data?.checklist;
      } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: FETCH checklist failed'));
      }
    }
  }
}
