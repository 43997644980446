import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchUserFromServer = async function () {
    try {
        let response = await axios.get('/api/users');
        if (response.data?.user) {
            return { user: response.data.user };
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error fetching the user from the server'));
    }
};


const updateUserOnServer = async function (payload) {
    let postBody = {};
    if (payload.hasOwnProperty('firstName')) {
        postBody['firstName'] = payload.firstName;
    }
    if (payload.hasOwnProperty('lastName')) {
        postBody['lastName'] = payload.lastName;
    }
    if (payload.hasOwnProperty('role')) {
        let studentOrInstructorObj;
        if (payload.role && payload.hasOwnProperty(payload.role)) {
            // Add payload.student or payload.instructor to the postBody for updates
            studentOrInstructorObj = payload[payload.role];
        } else {
            // Add true to the postBody for creating a new student or instructor
            studentOrInstructorObj = { isNewUser: true };
        }
        postBody[payload.role] = studentOrInstructorObj;
    }
    if (payload.hasOwnProperty('isIndependentUser') ||
        payload.hasOwnProperty('consentsToSharingSlideshows')) {
        postBody['student'] = payload;
    }
    if (payload.hasOwnProperty('isParticipatingInTheStudy')) {
        postBody['instructor'] = payload;
    }
    try {
        let response = await axios.put('/api/users', postBody);
        if (response.data.user) {
            return response.data.user;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: PUT /api/users failed'));
    }
};

export const setFirstNameOnServer = async function (payload) {
    if (!payload.hasOwnProperty('firstName')) throw new Error('Missing Parameter: setFirstNameOnServer requires that the payload contain <firstName>');
    return updateUserOnServer(payload);
};

export const setLastNameOnServer = async function (payload) {
    if (!payload.hasOwnProperty('lastName')) throw new Error('Missing Parameter: setLastNameOnServer requires that the payload contain <lastName>');
    return updateUserOnServer(payload);
};

// <role> must be either 'student' or 'instructor'
export const setRoleOnServer = async function (payload) {
    if (!payload.hasOwnProperty('role')) throw new Error('Missing Parameter: setRoleOnServer requires that the payload contain <role>');
    if (payload.role !== 'student' && payload.role !== 'instructor') throw new Error('<role> must be either "student" or "instructor"');
    return updateUserOnServer(payload);
};

export const setIsIndependentUserOnServer = async function (payload) {
    if (!payload.hasOwnProperty('isIndependentUser')) throw new Error('Missing Parameter: setIsIndependentUserOnServer requires that the payload contain <isIndependentUser>');
    return updateUserOnServer(payload);
};

export const setConsentsToSharingSlideshowsOnServer = async function (payload) {
    if (!payload.hasOwnProperty('consentsToSharingSlideshows')) throw new Error('Missing Parameter: setConsentsToSharingSlideshowsOnServer requires that the payload contain <consentsToSharingSlideshows>');
    return updateUserOnServer(payload);
};

export const setIsParticipatingInTheStudyOnServer = async function (payload) {
    if (!payload.hasOwnProperty('isParticipatingInTheStudy')) throw new Error('Missing Parameter: setIsParticipatingInTheStudyOnServer requires that the payload contain <isParticipatingInTheStudy>');
    return updateUserOnServer(payload);
};

export const deleteUserOnServer = async function (payload) {
    try {
        let response = await axios.delete('/api/users');
        let data = response.data;

        if (data.status === 'ok') {
            return data;
        } else {
            throw new Error('Error: Unexpected server response');
        }
    } catch (error) {
        throw new Error(getMeaningfulErrorMessage(error, 'Error: DELETE /api/users failed'));
    }
};
